<template>
  <div class="w-[90px] absolute pb-2 quickPanel z-10" :class="{ show: isShowQuickPanel }">
    <div class="relative">
      <div class="bg-[#19305b] rounded pb-1">
        <div class="flex w-full flex-col gap-10 !pt-5">
          <div class="flex items-center gap-2 text-lg" v-for="(item, idx) in items" :key="idx">
            <router-link :to="item.path" class="flex flex-col items-center gap-2 w-full">
              <div class="icon">
                <component :is="item.icon" class="w-8 h-8" />
              </div>
              <div class="px-1 text-xs font-bold text-white text-center">{{ item.title }}</div>
            </router-link>
          </div>
        </div>
        <el-button link size="large" class="!px-1 !py-2 w-full !mt-2" @click="toggleQuickPanel">
          <el-icon size="30" color="white">
            <Icon icon="carbon:close" />
          </el-icon>
        </el-button>
      </div>
      <el-button
        size="large"
        class="px-1 !py-6 w-full !mt-2 rounded !bg-[#ee312f] transition-all collapse-btn"
        :class="{ show: isShowQuickPanel }"
        @click="toggleQuickPanel"
      >
        <el-icon size="24" color="white">
          <i class="mdi mdi-folder-multiple"></i>
        </el-icon>
      </el-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, shallowRef } from 'vue'

import ApprovalIcon from '@/assets/icons/approval.svg?component'
import DashboardIcon from '@/assets/icons/dashboard.svg?component'
import EmailIcon from '@/assets/icons/email.svg?component'
import MessagesIcon from '@/assets/icons/messenger.svg?component'
import StatisticsIcon from '@/assets/icons/statistics.svg?component'
import { useMainStore } from '@/stores/main'

const mainStore = useMainStore()
const isShowQuickPanel = computed(() => mainStore.layout.quickPanel)
const items = ref([
  {
    title: 'Dashboard',
    icon: shallowRef(DashboardIcon),
    path: '/dashboard',
  },
  {
    title: 'Statistics & Trend Report',
    icon: shallowRef(StatisticsIcon),
    path: '/statistics',
  },
  {
    title: 'Messages',
    icon: shallowRef(MessagesIcon),
    path: '/messages',
  },
  {
    title: 'Email',
    icon: shallowRef(EmailIcon),
    path: '/email',
  },
  {
    title: 'Approval',
    icon: shallowRef(ApprovalIcon),
    path: '/approval',
  },
])

const toggleQuickPanel = () => {
  mainStore.toggleQuickPanel()
}
</script>

<style scoped lang="scss">
.quickPanel {
  top: 130px;
  right: -90px;
  position: fixed;
  transition: right 0.2s;
}
.show {
  right: 0;
}
.collapse-btn {
  position: fixed;
  right: 0;
  width: 70px;
  animation: fadeIn 0.2s;
  display: flex;
  &.show {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
