<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<template>
  <div class="flex flex-col gap-y-2">
    <el-table
      ref="dataTableRef"
      v-bind="$attrs"
      :data="props.data"
      :default-selected-rows="defaultSelectedRows"
      :border="border"
      :row-key="props.rowKey"
      stripe
      @selection-change="handleSelectionChange"
      @row-click="$emit('row-click', $event)"
      @row-dblclick="$emit('row-dblclick', $event)"
      @cell-dblclick="
        (row, col, event) => $emit('cell-dblclick', { row, col, event })
      "
      @sort-change="$emit('sort-change', $event)"
    >
      <el-table-column
        v-if="selectable"
        type="selection"
        width="34"
        :fixed="!disableFixedSelection"
        :selectable="rowSelectable ?? (() => true)"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :prop="item.key"
        :label="$t(item.label)"
        :sortable="item.sortable"
        :height="item.height"
        :min-width="item.minWidth"
        :width="item.width"
        :filters="item.filters"
        :filter-method="item.filterMethod"
        :fixed="item.fixed"
        :align="item.align || 'left'"
        :class-name="item.sortable ? 'cursor-pointer' : ''"
      >
        <template #header="header">
          <div :class="{ 'whitespace-nowrap': !nowrapHeader }">
            <span v-if="item.required" class="accent-text">*</span>
            {{ $t(item.label) }}
          </div>
          <div
            v-if="header.column.sortable"
            class="sort-icon ml-1 flex w-4 h-4 items-center justify-center"
          >
            <template v-if="header.column.order">
              <i
                v-if="header.column.order.includes('asc')"
                class="mdi mdi-menu-up text-[1.5rem]"
              ></i>
              <i
                v-if="header.column.order.includes('desc')"
                class="mdi mdi-menu-down text-[1.5rem]"
              ></i>
            </template>
            <i v-else class="mdi mdi-menu-down text-[1.5rem] opacity-30"></i>
          </div>
        </template>
        <template #default="{ row }">
          <template v-if="item.columns">
            <el-table-column
              v-for="child in item.columns"
              :key="child.key"
              :prop="child.key"
              :label="$t(child.label)"
              :sortable="child.sortable"
              :min-width="child.minWidth"
              :width="child.width"
              :filters="child.filters"
              :filter-method="child.filterMethod"
              :fixed="child.fixed"
              :align="child.align || 'left'"
            >
              <template #header="header">
                <div :class="{ 'whitespace-nowrap': !nowrapHeader }">
                  <span v-if="child.required" class="accent-text">*</span>
                  {{ $t(child.label) }}
                </div>
                <div
                  v-if="header.column.sortable"
                  class="sort-icon ml-1 flex w-4 h-4 items-center justify-center"
                >
                  <template v-if="header.column.order">
                    <i
                      v-if="header.column.order.includes('asc')"
                      class="mdi mdi-menu-up text-[1.5rem]"
                    ></i>
                    <i
                      v-if="header.column.order.includes('desc')"
                      class="mdi mdi-menu-down text-[1.5rem]"
                    ></i>
                  </template>
                  <i
                    v-else
                    class="mdi mdi-menu-down text-[1.5rem] opacity-30"
                  ></i>
                </div>
              </template>
              <template #default="{ row }">
                <div :class="getCellAlignment(item)">
                  <slot :name="`${child.key}`" :row="row">
                    <div v-if="!item.hideEmptyValue || row[item.key]">
                      {{ row[item.key]?.[child.key] }}
                    </div>
                  </slot>
                </div>
              </template>
            </el-table-column>
          </template>
          <div :class="getCellAlignment(item)">
            <slot :name="`${item.key}`" :row="row">
              <div v-if="!item.hideEmptyValue || row[item.key]">
                {{ row[item.key] }}
              </div>
            </slot>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="total && !showButtonRedirectFirstLastPage" class="flex">
      <div class="flex-1 mr-5">
        <div class="flex items-center justify-start gap-4 text-nowrap">
          <span class="summary">
            {{ totalText }}
          </span>
          <span>Rows per page</span>
          <el-select
            v-model="itemsPerPage"
            class="!w-16"
            @change="handleSizeChange"
          >
            <el-option
              v-for="item in [15, 20, 25, 30]"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-pagination
          v-model:page-size="itemsPerPage"
          v-model:current-page="currentPage"
          :pager-count="11"
          background
          :total="total"
          layout="slot, prev, pager, next"
          @current-change="$emit('page-change', $event)"
        >
        </el-pagination>
      </div>
      <div class="flex-1 hidden md:flex" />
    </div>
    <div v-if="total && showButtonRedirectFirstLastPage" class="flex">
      <div class="flex-1 mr-5">
        <div class="flex items-center justify-start gap-4 text-nowrap">
          <span class="summary">
            {{ totalText }}
          </span>
          <span>Rows per page</span>
          <el-select
            v-model="itemsPerPage"
            class="!w-16"
            @change="handleSizeChange"
          >
            <el-option
              v-for="item in [15, 20, 25, 30]"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="flex-1 justify-items-center flex items-center">
        <el-button
          class="mr-1 w-8 custom-pagination-db-arrow"
          :disabled="checkFirstOrLastPage(true)"
          @click="goToFirstPage"
        >
          <el-icon><DArrowLeft /></el-icon>
        </el-button>
        <el-pagination
          v-model:page-size="itemsPerPage"
          v-model:current-page="currentPage"
          :pager-count="11"
          background
          :total="total"
          layout="slot, prev, pager, next"
          @current-change="$emit('page-change', $event)"
        />
        <el-button
          class="ml-1 w-8 custom-pagination-db-arrow"
          :disabled="checkFirstOrLastPage(false)"
          @click="goToLastPage"
        >
          <el-icon><DArrowRight /></el-icon>
        </el-button>
      </div>
      <div class="flex-1 hidden md:flex" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElTable } from "element-plus";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { DArrowLeft, DArrowRight } from "@element-plus/icons-vue";

const emit = defineEmits([
  "selection-change",
  "row-click",
  "row-dblclick",
  "cell-dblclick",
  "sort-change",
  "page-change",
  "size-change",
  "is-scrolled-bottom",
]);
const props = defineProps<{
  data: Array<any>;
  columns: Array<any>;
  selectable?: boolean;
  total?: number;
  pageSize?: number;
  border?: boolean;
  nowrapHeader?: boolean;
  showButtonRedirectFirstLastPage?: boolean;
  rowKey?: string;
  listSelected?: any[];
  currentPage?: number;
  isInfiniteLoad?: boolean;
  clearChecked?: boolean;
  rowSelectable?: (row: any, index: number) => boolean;
  disableFixedSelection?: boolean;
}>();

const dataTableRef = ref<InstanceType<typeof ElTable>>();
const defaultSelectedRows = ref([]);
const itemsPerPage = ref(props.pageSize || 10);
const currentPage = ref(props.currentPage ?? 1);
const selection = ref<any[]>([]);

const totalText = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value + 1;
  const end = Math.min(start + props.data.length - 1, props.total ?? 0);
  return `${start} - ${end} of ${props.total}`;
});

const handleSizeChange = (val: number) => {
  itemsPerPage.value = val;
  emit("size-change", val);
};

const getCellAlignment = (column: { align: string; noTruncate: boolean }) => {
  const newClass = column.noTruncate ? "" : "truncate";
  if (column.align === "left") {
    return `text-left ${newClass}`;
  } else if (column.align === "right") {
    return `text-right ${newClass}`;
  }
  return `text-center ${newClass}`;
};

const handleSelectionChange = (val: any[]) => {
  selection.value = val;
  emit("selection-change", val);
};

const goToFirstPage = () => {
  currentPage.value = 1;
  emit("page-change", currentPage.value);
};

const goToLastPage = () => {
  const total = props.total ?? props.data?.length;
  const pageSize = props.pageSize ?? 5;

  currentPage.value = Math.ceil(total / pageSize);
  emit("page-change", currentPage.value);
};
const checkFirstOrLastPage = (isFirst: boolean) => {
  const total = props.total ?? props.data?.length;
  const pageSize = props.pageSize ?? 5;

  if (isFirst) {
    return currentPage.value === 1;
  }
  return currentPage.value === Math.ceil(total / pageSize);
};

const clearSort = () => {
  dataTableRef.value?.clearSort();
};

const setSelectedList = (list: any[], oldData: any[]) => {
  nextTick(() => {
    if (oldData?.length) {
      oldData.forEach((data) => {
        dataTableRef.value?.toggleRowSelection(data, false);
      });
    }
    if (list.length) {
      selection.value = list;
      selection.value.forEach((item) => {
        const row = props.data.find(
          (dataItem) => dataItem[props.rowKey!] === item,
        );
        if (row) {
          dataTableRef.value?.toggleRowSelection(row, true);
        }
      });
    }
  });
};

watch(
  () => props.pageSize,
  () => {
    if (props.pageSize) {
      itemsPerPage.value = props.pageSize;
    }
  },
);

watch(
  () => props.data,
  (_, oldValue) => {
    if (props.listSelected) {
      setSelectedList(props.listSelected, oldValue);
    }
  },
);

watch(
  () => props.currentPage,
  () => {
    if (props.currentPage && currentPage.value !== props.currentPage) {
      currentPage.value = props.currentPage;
    }
  },
);

watch(
  () => props.clearChecked,
  () => {
    if (props.clearChecked) {
      selection.value = [];
      dataTableRef.value!.clearSelection();
    }
  },
);

watch(
  () => props.isInfiniteLoad,
  () => {
    if (props.isInfiniteLoad) {
      listenScroll();
    }
  },
);

const listenScroll = () => {
  const scrollBar = dataTableRef.value?.$el.querySelectorAll(
    ".el-scrollbar__wrap",
  );
  scrollBar.forEach((scrollEl: any) => {
    const scrollBarView = scrollEl.querySelectorAll(".el-scrollbar__view");
    scrollBarView.forEach((viewEl: any) => {
      if (
        props.isInfiniteLoad &&
        viewEl.clientHeight <= scrollEl.clientHeight
      ) {
        emit("is-scrolled-bottom");
      }
    });
    let horizontalScrollPos = 0;
    scrollEl.addEventListener("scroll", () => {
      if (!props.isInfiniteLoad) return;
      if (scrollEl.scrollLeft == horizontalScrollPos) {
        const currentPos = scrollEl.scrollHeight - scrollEl.scrollTop;
        const isBottom =
          parseInt(currentPos.toString(), 10) <= scrollEl.clientHeight + 5;
        if (isBottom) emit("is-scrolled-bottom", isBottom);
      } else {
        horizontalScrollPos = scrollEl.scrollLeft;
      }
    });
  });
};

onMounted(() => {
  if (props.listSelected) {
    setSelectedList(props.listSelected, []);
  }
  if (props.isInfiniteLoad) {
    listenScroll();
  }
});

defineExpose({
  dataTableRef,
  clearSort,
});
</script>

<style lang="scss" scoped>
.summary {
  background-color: var(--el-fill-color);
  @apply px-4 py-2 rounded;
}
.custom-pagination-db-arrow {
  background-color: var(--el-fill-color);
  @apply border-none;
}
.custom-pagination-db-arrow:disabled,
.custom-pagination-db-arrow:disabled:hover {
  color: var(--el-text-color-placeholder);
  background-color: var(--el-disabled-bg-color);
}
:deep(.el-table__body tr.current-row) {
  z-index: calc(var(--el-table-index) + 2);
  box-shadow:
    0 5px 5px -2px rgb(0 0 0 / 0.3),
    0 -5px 5px -2px rgb(0 0 0 / 0.3) !important;
  position: relative;
}
:deep(.el-table__body tr:hover) {
  box-shadow:
    0 2px 2px -2px #0000004d,
    0 -2px 2px -2px #0000004d;
}
:deep(.el-table__row:not(tr.current-row) > .el-table__cell) {
  position: unset;
}
:deep(.el-table__body tr.current-row > td.el-table__cell),
:deep(
    .el-table--striped.el-table__bodytr.el-table__row--striped.current-row
      td.el-table__cell
  ) {
  background-color: white !important;
}
.dashboard-table {
  --el-table-border-color: none;
  --el-table-bg-color: none;
  :deep(.el-table th.el-table__cell) {
    background-color: unset !important;
    border: none !important;
  }
  :deep(.el-table) {
    border-top: none !important;
  }
  :deep(.el-table tr) {
    background-color: unset !important;
  }
  :deep(.el-table td.el-table__cell) {
    border-bottom: none !important;
  }
  :deep(.el-table__body td.el-table__cell) {
    padding: 0;
    height: 46px;
    background: none !important;
  }
  :deep(.el-table__body tr:hover) {
    box-shadow: none !important;
  }
}
</style>
