import { SubjectAbility } from 'shared/types/ability'
import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const SupplyRelease = defineAsyncComponentWithOptions(() => import('ewmsRelease/SupplyRelease.vue'))
const VesselMovementRelease = defineAsyncComponentWithOptions(
  () => import('ewmsRelease/VesselMovementRelease.vue')
)

const ewmsReleaseRouter = [
  {
    path: '/ewms/goods-release/supply-release',
    name: SubjectAbility.EWMS_GOODS_RELEASE_SUPPLY,
    component: SupplyRelease,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      searchable: true,
      title: 'supply',
      locales: [KainosMessageByModule.EWMS, KainosMessageByModule.COMMON],
      breadCrumbs: [{ text: 'ewms' }, { text: 'goodsRelease' }, { text: 'supply', isActive: true }],
    },
  },
  {
    path: '/ewms/goods-release/vessel-movement-release',
    name: SubjectAbility.EWMS_GOODS_RELEASE_VESSEL,
    component: VesselMovementRelease,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      searchable: true,
      title: 'vesselMovement',
      locales: [KainosMessageByModule.EWMS, KainosMessageByModule.COMMON],
      breadCrumbs: [
        { text: 'ewms' },
        { text: 'goodsRelease' },
        { text: 'vesselMovement', isActive: true },
      ],
    },
  },
]

export default ewmsReleaseRouter
