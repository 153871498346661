import { SubjectAbility } from 'shared/types/ability'
import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const SupplyReceipt = defineAsyncComponentWithOptions(() => import('ewmsReceipt/SupplyReceipt.vue'))
const VesselMovementReceipt = defineAsyncComponentWithOptions(
  () => import('ewmsReceipt/VesselMovementReceipt.vue')
)

const ewmsReceiptRouter = [
  {
    path: '/ewms/goods-receipt/supply-receipt',
    name: SubjectAbility.EWMS_GOODS_RECEIPT_SUPPLY,
    component: SupplyReceipt,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      searchable: true,
      title: 'supply',
      locales: [KainosMessageByModule.EWMS, KainosMessageByModule.COMMON],
      breadCrumbs: [{ text: 'ewms' }, { text: 'goodsReceipt' }, { text: 'supply', isActive: true }],
    },
  },
  {
    path: '/ewms/goods-receipt/vessel-movement-receipt',
    name: SubjectAbility.EWMS_GOODS_RECEIPT_VESSEL,
    component: VesselMovementReceipt,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      searchable: true,
      title: 'vesselMovement',
      locales: [KainosMessageByModule.EWMS, KainosMessageByModule.COMMON],
      breadCrumbs: [
        { text: 'ewms' },
        { text: 'goodsReceipt' },
        { text: 'vesselMovement', isActive: true },
      ],
    },
  },
]

export default ewmsReceiptRouter
