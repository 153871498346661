import type { App } from 'vue'
import { Icon } from '@iconify/vue'

import DataTable from 'ui/src/core/DataTable.vue'
import ContentLayout from 'ui/src/layouts/ContentLayout.vue'

import PageHeader from './PageHeader.vue'

export default {
  install: (app: App) => {
    app.component('PageHeader', PageHeader)
    app.component('Icon', Icon)
    app.component('ContentLayout', ContentLayout)
    app.component('DataTable', DataTable)
  },
}
