<template>
  <!-- <div class="content-layout"> -->
  <div class="flex flex-col w-full gap-2">
    <div v-if="$slots['top-header']">
      <slot name="top-header"></slot>
    </div>
    <div v-if="$slots['content-header']">
      <slot name="content-header"></slot>
    </div>
    <div
      v-if="$slots.actions || $slots['left-actions']"
      class="flex items-center justify-end gap-2"
    >
      <div class="flex-1">
        <slot name="left-actions"></slot>
      </div>
      <div class="flex flex-wrap justify-end gap-1">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="overflow-auto flex flex-col !flex-1">
      <slot name="content"></slot>
    </div>
    <div v-if="$slots['footer']" class="overflow-auto flex flex-col items-end">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.content-layout {
  @apply flex flex-col w-full gap-5;
}
</style>
