<template>
  <div
    class="flex h-full items-center gap-x-4 border-b border-gray-200 bg-white !pl-5 shadow-sm !sm:gap-x-6 sm:pl-6 lg:pl-8"
  >
    <router-link to="/"
      ><div class="flex items-center cursor-pointer">
        <img class="h-8 w-[110px]" src="@/assets/images/hinavi-logo.png" alt="Hi Navi" /></div
    ></router-link>

    <div class="flex !flex-1 gap-x-4 self-stretch lg:gap-x-6">
      <div class="!flex-1"></div>
      <div class="flex items-center">
        <!-- icon email with number of new email -->
        <div class="h-full hidden md:flex">
          <ActionsNav />
        </div>

        <!-- Profile dropdown -->
        <UserBox :name="username || $t('message.getNameFailed')" />
        <div class="btn_full" @click="emits('toggleSidebar')">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { getTextByLanguage } from 'shared/utils/language-helpers'

import { globalI18n } from '@/locales'
import { useAuthStore } from '@/stores/auth'

import ActionsNav from './ActionsNav.vue'
import UserBox from './UserBox.vue'

const authStore = useAuthStore()

defineProps({
  isShowSidebar: Boolean,
})

const emits = defineEmits(['toggleSidebar'])

const username = computed(() => {
  const userInfo = authStore.getUserInfo
  return getTextByLanguage(
    globalI18n.locale,
    userInfo?.employeeEnglishName,
    userInfo?.employeeKoreanName
  )
})
</script>

<style lang="scss" scoped>
.btn_full {
  width: 90px;
  height: 70px;
  cursor: pointer;
  z-index: 100;
  background: #20265b;
  padding: 15px 28px;
}
.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 3px;
  background-color: #fff;
  margin: 8px 0;
  transition: 0.4s;
  border-radius: 5px;
}
.bar2 {
  width: 18px;
  text-align: right;
  margin-left: 15px;
}
.active {
  .bar1 {
    transform: translate(0, 9px) rotate(-45deg);
    background-color: #fff;
  }
  .bar2 {
    opacity: 0;
    background-color: #fff;
  }
  .bar3 {
    transform: translate(0, -13px) rotate(45deg);
    background-color: #fff;
  }
}
</style>
