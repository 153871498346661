import { SubjectAbility } from 'shared/types/ability'
import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const History = defineAsyncComponentWithOptions(() => import('ewmsHistory/History.vue'))

const ewmsHistoryRouter = [
  {
    path: '/ewms/history-info/history',
    name: SubjectAbility.EWMS_HISTORY_INFO_HISTORY,
    component: History,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      searchable: true,
      title: 'history',
      locales: [KainosMessageByModule.EWMS, KainosMessageByModule.COMMON],
      breadCrumbs: [{ text: 'ewms' }, { text: 'historyInfo' }, { text: 'history', isActive: true }],
    },
  },
]

export default ewmsHistoryRouter
