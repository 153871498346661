<template>
  <div class="flex">
    <div class="flex flex-col items-center justify-center w-full">
      <!-- <div v-for="nav in menuItemSample" :key="nav.name" class="w-full">
        <MenuItem
          :key="nav.name"
          :name="nav.name"
          :href="nav.path"
          :icon="nav.icon"
          :current="!!nav.current"
          :children="nav.children"
          @toggle-sub-menu="handleToggleMenu(nav)"
        />
      </div> -->
      <!-- 1depth 전체 클릭 시 이벤트 적용 -->
      <div
        v-for="nav in menuItemSample"
        :key="nav.name"
        class="w-full"
        @click="handleToggleMenu(nav)"
      >
        <MenuItem
          :key="nav.name"
          :name="nav.name"
          :href="nav.path"
          :icon="nav.icon"
          :current="!!nav.current"
          :children="nav.children"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, watchEffect } from 'vue'
import { getTextByLanguage } from 'shared/utils/language-helpers'
import { useRoute } from 'vue-router'

import { i18n } from '@/locales'
import { normalizeMenuKey } from '@/shared/utils/permission'
import { menuSideBars } from '@/stores/app-sidebar-menu'
import { useAuthStore } from '@/stores/auth'
import { Menu as UserMenu } from '@/types'

import { menuItemSample } from './MenuData'
import MenuItem from './MenuItem.vue'
import type { Menu } from './type'

const route = useRoute()
const authStore = useAuthStore()

const menuItems = ref<Menu[]>([])

const handleToggleMenu = (menu: Menu) => {
  menuItemSample.value.forEach((item) => {
    if (item.key === menu.key) {
      item.current = !item.current
    } else {
      item.current = false
    }
  })
}

// handle router change
watchEffect(() => {
  const currentPath = route.path
  menuItemSample.value.forEach((item) => {
    if (item.path === currentPath) {
      item.current = true
    } else {
      item.current = false
    }
  })
})

const isMenuMatched = (keyA: string, keyB: string): boolean => {
  if (typeof keyA !== 'string' || typeof keyB !== 'string') {
    return false
  }
  return normalizeMenuKey(keyA) === normalizeMenuKey(keyB)
}

const findMenuMatched = (menus: UserMenu[], menuToSearch: Menu): UserMenu => {
  return menus.find((menu: UserMenu) => isMenuMatched(menu.key, menuToSearch.key) && menu.isVisible)
}

const handleDisplayMenus = (newMenus = authStore.menus) => {
  if (!menuItems.value.length) {
    menuItems.value = menuSideBars.filter((menu: Menu) =>
      newMenus.some(
        (newMenu: UserMenu) => isMenuMatched(menu.key, newMenu.key) && newMenu.isVisible
      )
    )
  }

  menuItems.value = menuItems.value.reduce((menus: Menu[], item: Menu) => {
    const matchedMenu = findMenuMatched(newMenus, item)
    if (!matchedMenu) {
      return menus
    }
    const updatedMenu = {
      ...item,
      name: getTextByLanguage(i18n.global.locale, matchedMenu.name, matchedMenu.koreanName),
    }

    if (item.children) {
      updatedMenu.children = item.children.reduce((menusLevel2: Menu[], level2Item: Menu) => {
        const matchedMenuLevel2 = findMenuMatched(matchedMenu.children, level2Item)
        if (!matchedMenuLevel2) {
          return menusLevel2
        }
        const updatedMenuLevel2 = {
          ...level2Item,
          name: getTextByLanguage(
            i18n.global.locale,
            matchedMenuLevel2.name,
            matchedMenuLevel2.koreanName
          ),
        }

        if (level2Item.children) {
          updatedMenuLevel2.children = level2Item.children.reduce(
            (menusLevel3: Menu[], level3Item: Menu) => {
              const matchedLevel3 = findMenuMatched(matchedMenuLevel2.children, level3Item)
              if (!matchedLevel3) {
                return menusLevel3
              }
              menusLevel3.push({
                ...level3Item,
                name: getTextByLanguage(
                  i18n.global.locale,
                  matchedLevel3.name,
                  matchedLevel3.koreanName
                ),
              })
              return menusLevel3
            },
            []
          )
        }
        menusLevel2.push(updatedMenuLevel2)
        return menusLevel2
      }, [])
    }
    menus.push(updatedMenu)
    return menus
  }, [])
}

watch(
  () => authStore.menus,
  (newMenus: UserMenu[]) => {
    handleDisplayMenus(newMenus)
  }
)

onMounted(() => {
  handleDisplayMenus()
})
</script>
