<template>
  <el-dialog v-model="isVisible" class="reset-pwd-message-box" @close="handleClose">
    <div class="popup-header">
      {{ titleMessagePopup }}
    </div>
    <p class="popup-message">{{ messagePopup }}</p>
    <template #footer>
      <el-button type="secondary" @click="handleClose">
        {{ $t('common.buttons.close') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  titleMessagePopup: {
    type: String,
    required: true,
  },
  messagePopup: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['close'])

const isVisible = ref(props.visible)

const handleClose = () => {
  emit('close')
}

// Watch for changes to the visible prop
watch(
  () => props.visible,
  (newVal) => {
    isVisible.value = newVal
  }
)
</script>

<style lang="scss" scoped>
@import 'ui/assets/scss/_variables';

.popup-header {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: $text-color-accent;
  padding: 10px;
}
.popup-message {
  font-size: $font-size-default;
  text-align: center;
  color: lighten($text-color-accent, 20%);
}
button {
  border: solid 1px $text-color-primary;
  color: $text-color-primary;
  font-weight: $font-weight-default;
}
</style>
