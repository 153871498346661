<template>
  <el-dialog
    v-model="isVisible"
    class="custom-dialog !rounded-lg"
    width="350"
    align-center
    @close="handleClose"
  >
    <ContentLayout>
      <template #content>
        <div class="items-center m-2 pt-2 pb-1">
          <div class="text-black px-2 text-center mb-1 py-1">
            <h4
              v-if="props.title"
              class="notify-mess font-extrabold accent-text pb-4"
            >
              {{ props.title }}
            </h4>
            <span v-if="props.message" class="notify-inf mt-4">
              {{ props.message }}
            </span>
          </div>
        </div>
      </template>
    </ContentLayout>
    <template #footer>
      <el-button class="secondary" @click="handleClose"
        >{{ $t("common.buttons.close") }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";

import ContentLayout from "../layouts/ContentLayout.vue";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  message: {
    type: String,
    default: "",
  },
  isShowDeleteButton: {
    type: Boolean,
    default: false,
  },
  widthPopup: {
    type: Number,
    default: null,
  },
  isDelete: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close"]);
const isVisible = ref(false);

const handleClose = () => {
  emit("close");
};

onMounted(() => {
  isVisible.value = props.visible;
});

watch(props, (newProps) => {
  if (newProps.visible !== isVisible.value) {
    isVisible.value = newProps.visible;
  }
});
</script>

<style>
.custom-dialog .el-dialog__header {
  background-color: white !important;
  border-radius: 10px !important;
}
.custom-dialog .el-dialog__footer {
  background-color: rgb(250, 250, 250) !important;
  border-end-end-radius: 10px !important;
  border-end-start-radius: 10px !important;
}
.custom-dialog .el-dialog__body {
  border: none !important;
}
</style>
