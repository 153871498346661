<!-- eslint-disable prettier/prettier -->
<template>
  <el-dialog
    v-model="isVisible"
    class="!h-[450px] !min-w-[600px] !max-w-[650px]"
    @close="handleClose"
  >
    <template #header>
      <div class="custom-dialog-header">
        {{ $t('common.labels.resetPassword') }}
      </div>
    </template>
    <content-layout>
      <template #content-header>
        <ul class="instruction-new-password">
          <li>{{ $t('common.messages.instructionPasswordReset1') }}</li>
          <li>{{ $t('common.messages.instructionPasswordReset2') }}</li>
        </ul>
        <el-descriptions :column="1" border class="overflow-x-auto">
          <!-- New Password Input -->
          <el-descriptions-item
            min-width="50"
            label-class-name="bg-primary-lighter"
            label-align="right"
          >
            <template #label>*&nbsp;{{ $t('common.labels.newPassword') }}</template>
            <div class="flex items-center">
              <el-input
                v-model="newPassword"
                :type="showNewPassword ? 'text' : 'password'"
                class="flex-1 mr-2"
                :placeholder="$t('common.placeholders.newPassword')"
                @blur="validateNewPassword"
              />
              <el-icon class="icon-size" @click="toggleNewPasswordVisibility">
                <Icon :icon="showNewPassword ? 'mdi:eye' : 'mdi:eye-off'" />
              </el-icon>
            </div>
          </el-descriptions-item>
          <!-- Confirm Password Input -->
          <el-descriptions-item
            min-width="50"
            label-class-name="bg-primary-lighter"
            label-align="right"
          >
            <template #label>*&nbsp;{{ $t('common.labels.confirmPassword') }}</template>
            <div class="flex items-center">
              <el-input
                v-model="confirmPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                class="flex-1 mr-2"
                :placeholder="$t('common.placeholders.confirmPassword')"
                @blur="validateConfirmPassword"
              />
              <el-icon class="icon-size" @click="toggleConfirmPasswordVisibility">
                <Icon :icon="showConfirmPassword ? 'mdi:eye' : 'mdi:eye-off'" />
              </el-icon>
            </div>
          </el-descriptions-item>
        </el-descriptions>
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      </template>
    </content-layout>
    <template #footer>
      <el-button type="secondary" @click="handleClose">{{ $t('common.buttons.close') }}</el-button>
      <el-button type="primary" :disabled="isSubmitting" @click="handleSubmit">
        {{ $t('common.buttons.ok') }}
      </el-button>
    </template>
  </el-dialog>
  <MessageResetPasswordPopup
    :visible="showMessageResetPassword"
    :title-message-popup="$t('common.messages.passwordChangeFailed')"
    :message-popup="passwordMessage"
    @close="handleCloseMessageResetPassword"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { ElNotification } from 'element-plus'
import { isPasswordValidate } from 'shared/utils'
import { useRoute } from 'vue-router'

import ContentLayout from 'ui/src/layouts/ContentLayout.vue'

import { globalI18n } from '@/locales'
import { useAuthStore } from '@/stores/auth'

import MessageResetPasswordPopup from './MessageResetPasswordPopup.vue'

const showMessageResetPassword = ref(false)
const props = defineProps({
  token: {
    type: String,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['close', 'submit', 'update:visible'])

const isVisible = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
})

const newPassword = ref('')
const confirmPassword = ref('')
const passwordMessage = ref('')
const successMessage = ref('')
const isSubmitting = ref(false)
const showNewPassword = ref(false)
const showConfirmPassword = ref(false)
const dataValidatePassword = ref({
  msgPwdBefore: '',
  msgPwdCheckId: '',
  msgPwdCombination: '',
  msgPwdExpirationPeriod: '',
  msgPwdInvalidCount: '',
  msgPwdMaxLength: '',
  msgPwdMinLength: '',
  publishDate: '',
  publishVersion: '',
  pwdCombination: '',
  pwdExpirationPeriod: 0,
  pwdInvalidCount: 0,
  pwdMaxLength: 0,
  pwdMinLength: 0,
  systemCode: '',
})

const route = useRoute()
const tokenReset: string = route.query.token.toString() || ''
const notifySuccess = (successMessage: string) => {
  ElNotification({
    title: globalI18n.t('common.messages.success'),
    message: successMessage,
    customClass: 'success-message',
    type: 'success',
    position: 'top-right',
    duration: 3000,
  })
}

const validatePasswordPolicy = (password: string): boolean => {
  const { pwdMinLength, pwdMaxLength, msgPwdMinLength, msgPwdMaxLength, msgPwdCombination } =
    dataValidatePassword.value

  if (password.length < pwdMinLength && password) {
    passwordMessage.value = msgPwdMinLength
    return false
  }

  if (password.length > pwdMaxLength && password) {
    passwordMessage.value = msgPwdMaxLength
    return false
  }

  if (!isPasswordValidate(password) && password.length > 0) {
    passwordMessage.value = msgPwdCombination
    return false
  }

  passwordMessage.value = ''
  return true
}

const validatePassword = (password: string): boolean => {
  const isValid = validatePasswordPolicy(password)
  showMessageResetPassword.value = !isValid
  return isValid
}

const validateNewPassword = (): boolean => validatePassword(newPassword.value)
const validateConfirmPassword = (): boolean => validatePassword(confirmPassword.value)
const validateMatchingPassword = (): boolean => {
  if (newPassword.value !== confirmPassword.value) {
    passwordMessage.value = globalI18n.t('common.messages.passwordDoNotMatch')
    showMessageResetPassword.value = true
    return false
  }
  return true
}
const toggleNewPasswordVisibility = () => {
  showNewPassword.value = !showNewPassword.value
}
const toggleConfirmPasswordVisibility = () => {
  showConfirmPassword.value = !showConfirmPassword.value
}
const handleSubmit = async () => {
  if (!validateNewPassword() || !validateConfirmPassword() || !validateMatchingPassword()) {
    return
  }
  isSubmitting.value = true
  passwordMessage.value = ''
  successMessage.value = ''

  try {
    const response = await useAuthStore().getEnterNewPassword(tokenReset, newPassword.value)
    if (response) {
      notifySuccess(globalI18n.t('common.messages.passwordChangeComplete'))
    }
  } catch (error) {
    passwordMessage.value = globalI18n.t('common.messages.passwordChangeFailed')
  } finally {
    isSubmitting.value = false
  }
}

const handleClose = () => {
  emit('submit')
  emit('close')
  // Reset form fields
  newPassword.value = ''
  confirmPassword.value = ''
  passwordMessage.value = ''
  successMessage.value = ''
}

const handleCloseMessageResetPassword = () => {
  showMessageResetPassword.value = false
}
// Reset form when dialog visibility changes
watch(
  () => props.visible,
  (newVal) => {
    if (!newVal) {
      newPassword.value = ''
      confirmPassword.value = ''
      passwordMessage.value = ''
      successMessage.value = ''
    }
  }
)

onMounted(async () => {
  dataValidatePassword.value = await useAuthStore().getPasswordPolicy()
})
</script>

<style lang="scss" scoped>
@import 'ui/assets/scss/_variables';
:deep(.el-overlay) {
  background-color: rgba(0, 0, 0, 0.7) !important; /* Use rgba for semi-transparent black */
}

:deep(.el-dialog) {
  background-color: #ffffff !important; /* Background color of the dialog */
}
.success-message {
  color: $text-color-success;
  font-weight: $font-weight-default;
  font-size: $font-size-default;
  .el-notification__body {
    background-color: $text-color-primary;
  }
  .el-notification__group {
    background-color: $text-color-primary !important;

    .el-notification__title {
      color: $text-color-primary !important;
    }

    .el-notification__content {
      line-height: 24px;
      margin: 6px 0 0;
      color: #ffffff !important;
    }
  }
}
.custom-dialog-header {
  color: $text-color-primary;
  font-weight: bold;
  font-size: 20px;
}

button {
  border: solid 1px $text-color-primary;
  color: $text-color-primary;
  font-weight: $font-weight-default;
}
footer button.el-button--primary {
  color: #ffffff;
}
.instruction-new-password {
  margin: 20px 0 30px 15px;
  font-size: $font-size-default;
  list-style-type: disc;
  li {
    margin-bottom: 5px;
  }
}

:deep(.el-descriptions__body .el-descriptions__table.is-bordered .el-descriptions__cell) {
  font-size: $font-size-default !important;
  font-weight: $font-weight-default !important;
}
:deep(.el-input__wrapper) {
  height: 36.22px !important;
  background-color: lighten($text-color-primary, 67%) !important;
}
.error-message {
  margin-top: 15px;
  color: $text-color-accent;
  font-weight: $font-weight-default;
  font-size: $font-size-default;
  display: block;
}
.el-icon {
  cursor: pointer;
}
.icon-size {
  font-size: 26px;
}
:deep(.el-notification__content p) {
  font-size: $font-size-default;
  color: red !important;
}
</style>
