/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|      CORE LIBS      |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
import { createApp } from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ElementPlus from 'element-plus'
import enEn from 'element-plus/es/locale/lang/en'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

// import * as ElementPlusIconsVue from "@element-plus/icons-vue"

/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|  THIRD PARTS LIBS   |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
import '@mdi/font/css/materialdesignicons.min.css'
import 'balloon-css/balloon.min.css'
import 'viewerjs/dist/viewer.css'
import 'splitpanes/dist/splitpanes.css'

import vClickOutside from 'v-click-outside'
import VueViewer from 'v-viewer'
import VueHighlightJS from 'vue3-highlightjs'

import 'highlight.js/styles/solarized-light.css'

import mavonEditor from 'mavon-editor'
import VueChartkick from 'vue-chartkick'

import 'chartkick/chart.js'
/* ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|     CORE ASSETS     |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ */
import 'animate.css'
import 'flex.box/src/flexbox.css'
import 'ui/assets/scss/global.scss'

import App from '@/App.vue'
import { useAuthStore } from '@/stores/auth'

import components from './components'
import { useCasl } from './core/casl'
import { i18n } from './locales'
import router from './router'

const pinia = createPinia()
pinia.use(createPersistedState())
const app = createApp(App)

/* ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ *\
|  THIRD PARTS COMPONENTS |
\* ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ ═ */
useCasl(app)
app.use(i18n)
app.use(pinia)
app.use(router)
app.use(VueChartkick)
app.use(VueViewer)
app.use(vClickOutside)
app.use(mavonEditor)
app.use(VueHighlightJS)
app.use(components)
app.use(ElementPlus, {
  locale: enEn,
  zIndex: 100,
})
app.use(CKEditor)

useAuthStore().initializeStore()

/*
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
*/

app.mount('#app')
