<template>
  <div
    class="flex items-center justify-center min-h-24 hover:bg-slate-100 py-2 cursor-pointer"
    :class="{ '!bg-slate-300': current }"
  >
    <a
      v-if="isExternalLink(href)"
      :href="href"
      target="_blank"
      rel="noopener noreferrer"
      class="flex w-full h-full flex-col items-center justify-center px-2 select-none"
    >
      <component :is="icon" class="h-10" />
      <span class="text-sm mt-1 text-center">{{ name }}</span>
    </a>
    <router-link
      v-else-if="href"
      :to="href"
      class="flex w-full h-full flex-col items-center justify-center px-2 select-none"
    >
      <component :is="icon" class="h-10" />
      <span class="text-sm mt-1 text-center">{{ name }}</span>
    </router-link>
    <div
      v-else
      class="flex w-full h-full flex-col items-center justify-center px-2 select-none"
      @click="$emit('toggleSubMenu')"
    >
      <component :is="icon" class="h-10" />
      <span class="text-xs mt-1 text-center">{{ name }}</span>
    </div>
    <Transition name="fade">
      <SubMenuContainer
        v-if="current && children"
        :menu-items="children"
        :title="name"
        @close="$emit('toggleSubMenu')"
      />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import SubMenuContainer from './SubMenuContainer.vue'
import type { Menu } from './type'

defineProps<{
  name: string
  href: string
  icon: object
  current: boolean
  children?: Menu[]
}>()

const isExternalLink = (href: string) => {
  return href && (href.startsWith('http://') || href.startsWith('https://'))
}

defineEmits(['toggleSubMenu'])
</script>

<style scoped lang="scss">
.fade-enter-active {
  transition:
    opacity 0.2s,
    transform 0.2s;
}
.fade-leave-active {
  transition:
    opacity 0.1s,
    transform 0.1s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0.5;
  transform: translateY(20px);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateX(0);
}
a {
  color: #333 !important;
  text-decoration: none;
}
</style>
