<template>
  <div v-if="meta.breadCrumbs && meta.title" class="main-breadcrumb">
    <div class="breadcrumb-title">
      <span>{{ $t('common.menus.' + meta.title) }} </span>
      <span v-if="meta.isSpecial">
        <StarTitleIcon class="star-title-icon" />
      </span>
    </div>
    <el-breadcrumb separator=">" class="breadcrumb-path">
      <el-breadcrumb-item>
        <RouterLink :to="{ path: '/' }">
          <el-icon><House /></el-icon>
        </RouterLink>
      </el-breadcrumb-item>
      <template v-for="(item, index) in meta.breadCrumbs" :key="index">
        <el-breadcrumb-item>
          <span :class="{ 'primary-text active': item.isActive }">{{
            $t('common.menus.' + item.text)
          }}</span>
        </el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { House } from '@element-plus/icons-vue'
import type { MetaData } from 'shared/types'
import { RouterLink, useRoute } from 'vue-router'

import StarTitleIcon from '@/assets/icons/star-title.svg?component'

const route = useRoute()

const meta = computed<MetaData>(() => {
  return route.meta as unknown as MetaData
})
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .main-breadcrumb {
    padding: 0 10px 0 10px !important;
  }
}
.main-breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  background-color: #f8f9fe;

  .breadcrumb-path {
    display: flex;
    align-items: center;
    font-size: 10pt;
    color: #000000;
    margin-left: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .breadcrumb-title {
    font-size: 12pt;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      &:first-child {
        font-size: 20px;
      }
    }
    .star-title-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
    }
  }
}

:deep(.el-breadcrumb__item) {
  display: flex;
  align-items: center;
}

span.active {
  font-weight: bold;
}
</style>
