export enum ActionsAbility {
    VISIBLE = "VISIBLE",
    PRINT = "PRINT",
    RETRIEVE = "RETRIEVE",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
    ADMIN = "manage",
}

export enum SubjectAbility {
    // Home
    HOME = "HOME",
    MAIN = "MAIN",
    USER_INFORMATION = "USER_INFORMATION",
    VESSEL_BUDGET = "VESSEL_BUDGET",
    HOME_FAVORITE = "HOME_FAVORITE",
    HOME_SITEMAP = "HOME_SITEMAP",

    // Common Code
    COMMON_CODE = "COMMON_CODE",
    COMMON_CODE_VESSEL_CODE = "COMMON_CODE_VESSEL_CODE",
    COMMON_CODE_CREW_CODE = "COMMON_CODE_CREW_CODE",
    COMMON_CODE_COMPANY_CODE = "COMMON_CODE_COMPANY_CODE",
    COMMON_CODE_PORT_CODE = "COMMON_CODE_PORT_CODE",
    COMMON_CODE_DEPARTMENT_CODE = "COMMON_CODE_DEPARTMENT_CODE",
    COMMON_CODE_MASTER_CODE = "COMMON_CODE_MASTER_CODE",
    COMMON_CODE_SAFETY_CODE = "COMMON_CODE_SAFETY_CODE",

    // Approval
    APPROVAL = "APPROVAL",
    IN_PROGRESS = "IN_PROGRESS",
    MY_DRAFT = "MY_DRAFT",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    FOR_APPROVAL = "FOR_APPROVAL",
    REFERENCE = "REFERENCE",
    SIGNATURE = "SIGNATURE",
    APPROVAL_NEW_DRAFT = "APPROVAL_NEW_DRAFT",

    // Ship mail
    SHIP_MAIL = "SHIP_MAIL",
    SHIP_MAIL_ADDRESS = "SHIP_MAIL_ADDRESS",
    SHIP_MAIL_INBOX = "SHIP_MAIL_INBOX",
    SHIP_MAIL_SENT = "SHIP_MAIL_SENT",
    SHIP_MAIL_OFFICIAL_LETTER = "SHIP_MAIL_OFFICIAL_LETTER",
    SHIP_MAIL_UNREGISTERED_MAIL = "SHIP_MAIL_UNREGISTERED_MAIL",

    ALL = "all",

    // Personal email
    PERSONAL_MAIL = "PERSONAL_MAIL",
    PERSONAL_MAIL_SAVED = "PERSONAL_MAIL_SAVED",
    PERSONAL_MAIL_INBOX = "PERSONAL_MAIL_INBOX",
    PERSONAL_MAIL_SENT = "PERSONAL_MAIL_SENT",
    PERSONAL_MAIL_FRIEND_LIST = "PERSONAL_MAIL_FRIEND_LIST",
    SAVED = "SAVED",
    PERSONAL_MAIL_NEW_MAIL = "PERSONAL_MAIL_NEW_MAIL",

    // Team mail
    TEAM_MAIL = "TEAM_MAIL",
    TEAM_MAIL_INBOX = "TEAM_MAIL_INBOX",
    TEAM_MAIL_SENT = "TEAM_MAIL_SENT",
    TEAM_MAIL_UNREGISTERED_MAIL = "TEAM_MAIL_UNREGISTERED_MAIL",
    TEAM_MAIL_SAVED = "TEAM_MAIL_SAVED",
    TEAM_MAIL_DELETED = "TEAM_MAIL_DELETED",
    TEAM_MAIL_ADDRESS = "TEAM_MAIL_ADDRESS",
    TEAM_MAIL_NEW_MAIL = "TEAM_MAIL_NEW_MAIL",

    // Administration
    ADMINISTRATION = "ADMINISTRATION",
    USER_MANAGE = "USER_MANAGE",
    MENU_LIST = "MENU_LIST",
    USER_AUTHORITY = "USER_AUTHORITY",
    HOME_ADMINISTRATION = "HOME_ADMINISTRATION",
    HOME_ADMINISTRATION_USER_AUTHORITY = "HOME_ADMINISTRATION_USER_AUTHORITY",
    MAIL_SECURITY = "MAIL_SECURITY",
    ADMINISTRATION_MAIL_SECURITY = "ADMINISTRATION_MAIL_SECURITY",
    ADMINISTRATION_MAIL_ADDRESS_MANAGEMENT = "ADMINISTRATION_MAIL_ADDRESS_MANAGEMENT",
    ADMINISTRATION_HELP = "ADMINISTRATION_HELP",
    ADMINISTRATION_EPRO_ADMIN = "ADMINISTRATION_EPRO_ADMIN",
    ADMINISTRATION_EPRO_BOARD = "ADMINISTRATION_EPRO_BOARD",
    ADMINISTRATION_EPRO_BOARD_NOTICE = "ADMINISTRATION_EPRO_BOARD_NOTICE",
    ADMINISTRATION_EPRO_BOARD_FAQ = "ADMINISTRATION_EPRO_BOARD_FAQ",
    ADMINISTRATION_EPRO_BOARD_NEWS = "ADMINISTRATION_EPRO_BOARD_NEWS",
    ADMINISTRATION_EPRO_BOARD_QA = "ADMINISTRATION_EPRO_BOARD_QA",
    ADMINISTRATION_EWMS_ADMIN = "ADMINISTRATION_EWMS_ADMIN",
    ADMINISTRATION_EWMS_BOARD = "ADMINISTRATION_EWMS_BOARD",
    ADMINISTRATION_EWMS_BOARD_NOTICE = "ADMINISTRATION_EWMS_BOARD_NOTICE",
    ADMINISTRATION_EWMS_BOARD_FAQ = "ADMINISTRATION_EWMS_BOARD_FAQ",
    ADMINISTRATION_EWMS_BOARD_NEWS = "ADMINISTRATION_EWMS_BOARD_NEWS",
    ADMINISTRATION_EWMS_BOARD_QA = "ADMINISTRATION_EWMS_BOARD_QA",
    SUPPORT_BOARD = "SUPPORT_BOARD",
    SUPPORT_BOARD_NOTICE = "SUPPORT_BOARD_NOTICE",
    SUPPORT_BOARD_FAQ = "SUPPORT_BOARD_FAQ",
    SUPPORT_BOARD_NEWS = "SUPPORT_BOARD_NEWS",
    SUPPORT_BOARD_QA = "SUPPORT_BOARD_QA",

    // Safety
    SAFETY = "SAFETY",
    ACCIDENT_NEAR_MISS = "ACCIDENT_NEAR_MISS",
    ACCIDENT_NEAR_MISS_EVENT_LIST = "ACCIDENT_NEAR_MISS_EVENT_LIST",
    ACCIDENT_NEAR_MISS_STATISTICS_TREND = "ACCIDENT_NEAR_MISS_STATISTICS_TREND",
    ACCIDENT_NEAR_MISS_SEARCH = "ACCIDENT_NEAR_MISS_SEARCH",

    SAFETY_PSC_FSC = "SAFETY_PSC_FSC",
    SAFETY_PSC_FSC_LIST = "SAFETY_PSC_FSC_LIST",
    SAFETY_PSC_FSC_DEFICIENCY_LIST = "SAFETY_PSC_FSC_DEFICIENCY_LIST",
    SAFETY_PSC_FSC_DEFICIENCY_MONITORING = "SAFETY_PSC_FSC_DEFICIENCY_MONITORING",
    SAFETY_PSC_FSC_STATISTICS_TREND = "SAFETY_PSC_FSC_STATISTICS_TREND",
    SAFETY_PSC_FSC_INQUIRY_BY = "SAFETY_PSC_FSC_INQUIRY_BY",

    SAFETY_SIRE = "SAFETY_SIRE",
    SAFETY_SIRE_LIST = "SAFETY_SIRE_LIST",
    SAFETY_SIRE_OBSERVATION_LIST = "SAFETY_SIRE_OBSERVATION_LIST",
    SAFETY_SIRE_DEFICIENCY_LIST = "SAFETY_SIRE_DEFICIENCY_LIST",

    SAFETY_OTHER = "SAFETY_OTHER",
    SAFETY_OTHER_OTHER_LIST = "SAFETY_OTHER_OTHER_LIST",
    SAFETY_OTHER_DEFICIENCY_LIST = "SAFETY_OTHER_DEFICIENCY_LIST",

    SAFETY_AUDIT = "SAFETY_AUDIT",
    SAFETY_AUDIT_REPORT_LIST = "SAFETY_AUDIT_REPORT_LIST",
    SAFETY_AUDIT_EXTERNAL_AUDIT_STATUS = "SAFETY_AUDIT_EXTERNAL_AUDIT_STATUS",
    SAFETY_AUDIT_CORRECTIVE_ACTION_LIST = "SAFETY_AUDIT_CORRECTIVE_ACTION_LIST",
    SAFETY_AUDIT_CONFIRMATION_LIST = "SAFETY_AUDIT_CONFIRMATION_LIST",
    SAFETY_AUDIT_EFFECTIVENESS_LIST = "SAFETY_AUDIT_EFFECTIVENESS_LIST",
    SAFETY_AUDIT_PROCESS_MONITORING = "SAFETY_AUDIT_PROCESS_MONITORING",
    SAFETY_AUDIT_CHECKLIST_REGISTER = "SAFETY_AUDIT_CHECKLIST_REGISTER",
    SAFETY_AUDIT_QUALIFICATION_MANAGEMENT = "SAFETY_AUDIT_QUALIFICATION_MANAGEMENT",

    SAFETY_PERSONNEL_HISTORY = "SAFETY_PERSONNEL_HISTORY",

    SAFETY_WORK_PERMIT = "SAFETY_WORK_PERMIT",
    SAFETY_WORK_PERMIT_LIST = "SAFETY_WORK_PERMIT_LIST",
    SAFETY_WORK_PERMIT_HOT_WORK = "SAFETY_WORK_PERMIT_HOT_WORK",
    SAFETY_WORK_PERMIT_HOT_WORK_HIGH_RISK_AREA = "SAFETY_WORK_PERMIT_HOT_WORK_HIGH_RISK_AREA",
    SAFETY_WORK_PERMIT_ENCLOSED_SPACE = "SAFETY_WORK_PERMIT_ENCLOSED_SPACE",
    SAFETY_WORK_PERMIT_ELECTRIC_WORK = "SAFETY_WORK_PERMIT_ELECTRIC_WORK",
    SAFETY_WORK_PERMIT_HIGH_VOLTAGE = "SAFETY_WORK_PERMIT_HIGH_VOLTAGE",
    SAFETY_WORK_PERMIT_ALOFT_OVERSIDE = "SAFETY_WORK_PERMIT_ALOFT_OVERSIDE",
    SAFETY_WORK_PERMIT_PIPELINES_PRESSURE_VESSELS = "SAFETY_WORK_PERMIT_PIPELINES_PRESSURE_VESSELS",
    SAFETY_WORK_PERMIT_SIMOPS = "SAFETY_WORK_PERMIT_SIMOPS",
    SAFETY_WORK_PERMIT_OTHERS = "SAFETY_WORK_PERMIT_OTHERS",
    SAFETY_WORK_PERMIT_HEAVY_LIST = "SAFETY_WORK_PERMIT_HEAVY_LIST",
    SAFETY_WORK_PERMIT_OUTSIDE_WORK = "SAFETY_WORK_PERMIT_OUTSIDE_WORK",

    SAFETY_RISK_ASSESSMENT = "SAFETY_RISK_ASSESSMENT",
    SAFETY_RISK_ASSESSMENT_WORK_ACTIVITY = "SAFETY_RISK_ASSESSMENT_WORK_ACTIVITY",
    SAFETY_RISK_ASSESSMENT_RISK_ASSESSMENT = "SAFETY_RISK_ASSESSMENT_RISK_ASSESSMENT",
    SAFETY_RISK_ASSESSMENT_RISK_LIBRARY = "SAFETY_RISK_ASSESSMENT_RISK_LIBRARY",

    SAFETY_ESQS_REPORT = "SAFETY_ESQS_REPORT",
    SAFETY_ESQS_REPORT_EXPERIENCE_FEEDBACK = "SAFETY_ESQS_REPORT_EXPERIENCE_FEEDBACK",
    SAFETY_ESQS_REPORT_SHIPBOARD_ESQ_COMMITTEE = "SAFETY_ESQS_REPORT_SHIPBOARD_ESQ_COMMITTEE",
    SAFETY_ESQS_REPORT_MASTER_REVIEW = "SAFETY_ESQS_REPORT_MASTER_REVIEW",
    SAFETY_ESQS_REPORT_MOC = "SAFETY_ESQS_REPORT_MOC",

    SAFETY_ENVIRONMENTAL_ENERGY_PROGRAM = "SAFETY_ENVIRONMENTAL_ENERGY_PROGRAM",
    SAFETY_ENVIRONMENTAL_ENERGY_PROGRAM_PERFORMANCE = "SAFETY_ENVIRONMENTAL_ENERGY_PROGRAM_PERFORMANCE",
    SAFETY_ENVIRONMENTAL_ENERGY_PROGRAM_ANALYSIS = "SAFETY_ENVIRONMENTAL_ENERGY_PROGRAM_ANALYSIS",

    SAFETY_SUPERINTENDENT_VISIT_REPORT = "SAFETY_SUPERINTENDENT_VISIT_REPORT",
    SAFETY_SUPERINTENDENT_VISIT_REPORT_LIST = "SAFETY_SUPERINTENDENT_VISIT_REPORT_LIST",
    SAFETY_SUPERINTENDENT_VISIT_REPORT_DETAIL = "SAFETY_SUPERINTENDENT_VISIT_REPORT_DETAIL",
    SAFETY_SUPERINTENDENT_VISIT_REPORT_DEFICIENCY_LIST = "SAFETY_SUPERINTENDENT_VISIT_REPORT_DEFICIENCY_LIST",

    SAFETY_ANALYSIS = "SAFETY_ANALYSIS",
    SAFETY_ANALYSIS_LIST = "SAFETY_ANALYSIS_LIST",
    SAFETY_ANALYSIS_DETAIL = "SAFETY_ANALYSIS_DETAIL",
    SAFETY_ANALYSIS_DEFICIENCY_LIST = "SAFETY_ANALYSIS_DEFICIENCY_LIST",

    SAFETY_SHIPBOARD_TRAINING_CONTENTS = "SAFETY_SHIPBOARD_TRAINING_CONTENTS",
    SAFETY_SHIPBOARD_TRAINING_CONTENTS_LIST = "SAFETY_SHIPBOARD_TRAINING_CONTENTS_LIST",
    SAFETY_SHIPBOARD_TRAINING_CONTENTS_HISTORY = "SAFETY_SHIPBOARD_TRAINING_CONTENTS_HISTORY",
    SAFETY_SHIPBOARD_TRAINING_CONTENTS_CONTENT_CATEGORY_COURSE = "SAFETY_SHIPBOARD_TRAINING_CONTENTS_CONTENT_CATEGORY_COURSE",

    SAFETY_SECODE = "SAFETY_SECODE",
    SAFETY_SECODE_LIST = "SAFETY_SECODE_LIST",
    SAFETY_SECODE_REPORT = "SAFETY_SECODE_REPORT",
    SAFETY_SECODE_MOC = "SAFETY_SECODE_MOC",
    SAFETY_SECODE_AUDIT = "SAFETY_SECODE_AUDIT",
    SAFETY_SECODE_ACCIDENT = "SAFETY_SECODE_ACCIDENT",
    SAFETY_SECODE_PSC = "SAFETY_SECODE_PSC",

    // Document
    DOCUMENT = "DOCUMENT",
    DOCUMENT_ESQS_DOCUMENTS = "DOCUMENT_ESQS_DOCUMENTS",
    DOCUMENT_ESQS_DOCUMENT_INTERNAL_DOCUMENT = "DOCUMENT_ESQS_DOCUMENT_INTERNAL_DOCUMENT",
    DOCUMENT_ESQS_DOCUMENT_EXTERNAL_DOCUMENT = "DOCUMENT_ESQS_DOCUMENT_EXTERNAL_DOCUMENT",
    DOCUMENT_ESQS_DOCUMENT_SEARCH = "DOCUMENT_ESQS_DOCUMENT_SEARCH",
    DOCUMENT_ESQS_DOCUMENT_REGISTER = "DOCUMENT_ESQS_DOCUMENT_REGISTER",
    DOCUMENT_ESQS_DOCUMENT_DISTRIBUTION = "DOCUMENT_ESQS_DOCUMENT_DISTRIBUTION",
    DOCUMENT_ESQS_DOCUMENT_CATEGORY_NODE = "DOCUMENT_ESQS_DOCUMENT_CATEGORY_NODE",
    DOCUMENT_ESQS_DOCUMENT_ESTABLISHMENT = "DOCUMENT_ESQS_DOCUMENT_ESTABLISHMENT",
    DOCUMENT_ESQS_DOCUMENT_REVISION = "DOCUMENT_ESQS_DOCUMENT_REVISION",

    DOCUMENT_CERTIFICATE = "DOCUMENT_CERTIFICATE",
    DOCUMENT_CERTIFICATE_LIST = "DOCUMENT_CERTIFICATE_LIST",
    DOCUMENT_CERTIFICATE_REGISTER = "DOCUMENT_CERTIFICATE_REGISTER",
    DOCUMENT_CERTIFICATE_DETAIL = "DOCUMENT_CERTIFICATE_DETAIL",
    DOCUMENT_CERTIFICATE_CODE = "DOCUMENT_CERTIFICATE_CODE",

    DOCUMENT_VESSEL_DRAWING = "DOCUMENT_VESSEL_DRAWING",
    DOCUMENT_VESSEL_DRAWING_DRAWING_LIST_BY_VESSEL = "DOCUMENT_VESSEL_DRAWING_DRAWING_LIST_BY_VESSEL",
    DOCUMENT_VESSEL_DRAWING_DRAWING_LIST_BY_GROUP = "DOCUMENT_VESSEL_DRAWING_DRAWING_LIST_BY_GROUP",
    DOCUMENT_VESSEL_DRAWING_DRAWING_CATEGORY_MANAGER = "DOCUMENT_VESSEL_DRAWING_DRAWING_CATEGORY_MANAGER",

    // Procurement
    PROCUREMENT = "PROCUREMENT",
    PROCUREMENT_REQUISITION = "PROCUREMENT_REQUISITION",
    PROCUREMENT_VESSEL_REQUISITION = "PROCUREMENT_VESSEL_REQUISITION",
    PROCUREMENT_REQUISITION_SPARE = "PROCUREMENT_REQUISITION_SPARE",
    PROCUREMENT_VESSEL_REQUISITION_SPARE = "PROCUREMENT_VESSEL_REQUISITION_SPARE",
    PROCUREMENT_REQUISITION_STORE = "PROCUREMENT_REQUISITION_STORE",
    PROCUREMENT_VESSEL_REQUISITION_STORE = "PROCUREMENT_VESSEL_REQUISITION_STORE",
    PROCUREMENT_REQUISITION_OIL = "PROCUREMENT_REQUISITION_OIL",
    PROCUREMENT_VESSEL_REQUISITION_OIL = "PROCUREMENT_VESSEL_REQUISITION_OIL",
    PROCUREMENT_PROCESS_MONITOR = "PROCUREMENT_PROCESS_MONITOR",
    PROCUREMENT_VESSEL_PROCESS_MONITOR = "PROCUREMENT_VESSEL_PROCESS_MONITOR",
    PROCUREMENT_E_SUPPLY_PORTAL = "PROCUREMENT_E_SUPPLY_PORTAL",
    PROCUREMENT_E_SUPPLY_PORTAL_QUOTATION = "PROCUREMENT_E_SUPPLY_PORTAL_QUOTATION",
    PROCUREMENT_E_SUPPLY_PORTAL_ORDER = "PROCUREMENT_E_SUPPLY_PORTAL_ORDER",
    PROCUREMENT_E_SUPPLY_PORTAL_GOOD_RECEIPT = "PROCUREMENT_E_SUPPLY_PORTAL_GOOD_RECEIPT",
    PROCUREMENT_E_SUPPLY_PORTAL_PAYMENT_STATUS = "PROCUREMENT_E_SUPPLY_PORTAL_PAYMENT_STATUS",
    PROCUREMENT_E_SUPPLY_PORTAL_INVOICE = "PROCUREMENT_E_SUPPLY_PORTAL_INVOICE",
    PROCUREMENT_E_SUPPLY_PORTAL_CLAIM_LIST = "PROCUREMENT_E_SUPPLY_PORTAL_CLAIM_LIST",
    PROCUREMENT_RECEIPT = "PROCUREMENT_RECEIPT",
    PROCUREMENT_VESSEL_RECEIPT = "PROCUREMENT_VESSEL_RECEIPT",
    PROCUREMENT_VESSEL_BUDGET = "PROCUREMENT_VESSEL_BUDGET",
    PROCUREMENT_VESSEL_VESSEL_BUDGET = "PROCUREMENT_VESSEL_VESSEL_BUDGET",
    PROCUREMENT_MASTER_DATA = "PROCUREMENT_MASTER_DATA",
    PROCUREMENT_VESSEL_MASTER_DATA = "PROCUREMENT_VESSEL_MASTER_DATA",
    PROCUREMENT_MASTER_DATA_SPARE_PART = "PROCUREMENT_MASTER_DATA_SPARE_PART",
    PROCUREMENT_VESSEL_MASTER_DATA_SPARE_PART = "PROCUREMENT_VESSEL_MASTER_DATA_SPARE_PART",
    PROCUREMENT_MASTER_DATA_SPARE_PART_REGISTRATION = "PROCUREMENT_MASTER_DATA_SPARE_PART_REGISTRATION",
    PROCUREMENT_MASTER_DATA_STORE_REGISTRATION = "PROCUREMENT_MASTER_DATA_STORE_REGISTRATION",
    PROCUREMENT_VESSEL_MASTER_DATA_STORE_REGISTRATION = "PROCUREMENT_VESSEL_MASTER_DATA_STORE_REGISTRATION",
    PROCUREMENT_MASTER_DATA_SPARE_PART_CODE = "PROCUREMENT_MASTER_DATA_SPARE_PART_CODE",
    PROCUREMENT_MASTER_DATA_STORE_CODE = "PROCUREMENT_MASTER_DATA_STORE_CODE",
    PROCUREMENT_MASTER_DATA_SUPPLIER_CODE = "PROCUREMENT_MASTER_DATA_SUPPLIER_CODE",
    PROCUREMENT_STOCK_MANAGEMENT = "PROCUREMENT_STOCK_MANAGEMENT",
    PROCUREMENT_STOCK_MANAGEMENT_SPARE_PART_INVENTORY = "PROCUREMENT_STOCK_MANAGEMENT_SPARE_PART_INVENTORY",
    PROCUREMENT_STOCK_MANAGEMENT_STORE_INVENTORY = "PROCUREMENT_STOCK_MANAGEMENT_STORE_INVENTORY",
    PROCUREMENT_STOCK_MANAGEMENT_STOCK_DISPOSAL_REQUISITION = "PROCUREMENT_STOCK_MANAGEMENT_STOCK_DISPOSAL_REQUISITION",
    PROCUREMENT_STOCK_MANAGEMENT_STOCK_MOVEMENT_ORDER_LIST = "PROCUREMENT_STOCK_MANAGEMENT_STOCK_MOVEMENT_ORDER_LIST",
    PROCUREMENT_MASTER_DATA_OIL_CODE = "PROCUREMENT_MASTER_DATA_OIL_CODE",
    PROCUREMENT_CLAIM_REQUISITION = "PROCUREMENT_CLAIM_REQUISITION",
    PROCUREMENT_CLAIM_REQUISITION_LIST = "PROCUREMENT_CLAIM_REQUISITION_CLAIM_REQUISITION_LIST",
    PROCUREMENT_FOREIGN_REPORT = "PROCUREMENT_FOREIGN_REPORT",
    PROCUREMENT_FOREIGN_REPORT_FOREIGN_REPORT_LIST = "PROCUREMENT_FOREIGN_REPORT_FOREIGN_REPORT_LIST",
    PROCUREMENT_REQUISITION_BASKET = "PROCUREMENT_REQUISITION_BASKET",

    // Crew
    CREW = "CREW",
    CREW_PERSONAL = "CREW_PERSONAL",
    CREW_PERSONAL_BASIC_DATA = "CREW_PERSONAL_BASIC_DATA",
    CREW_PERSONAL_CONFIDENTIAL = "CREW_PERSONAL_CONFIDENTIAL",
    CREW_PERSONAL_INTERVIEW_SHEET = "CREW_PERSONAL_INTERVIEW_SHEET",
    CREW_PERSONAL_BASIC_DATA_TRANSFER = "CREW_PERSONAL_BASIC_DATA_TRANSFER",
    CREW_PERSONAL_REQUEST_EDIT_LIST = "CREW_PERSONAL_REQUEST_EDIT_LIST",

    CREW_WORK = "CREW_WORK",
    CREW_WORK_WORK_TIME_REPORT = "CREW_WORK_WORK_TIME_REPORT",
    CREW_WORK_OVERTIME_REPORTS = "CREW_WORK_OVERTIME_REPORTS",
    CREW_WORK_LOCAL_TIME_CONTROL = "CREW_WORK_LOCAL_TIME_CONTROL",

    CREW_CREW_CHANGE = "CREW_CREW_CHANGE",
    CREW_CREW_CHANGE_CHANGE_REQUEST = "CREW_CREW_CHANGE_CHANGE_REQUEST",
    CREW_CREW_CHANGE_CHANGE_PLAN_BY_POSITION = "CREW_CREW_CHANGE_CHANGE_PLAN_BY_POSITION",
    CREW_CREW_CHANGE_CHANGE_PLAN_BY_VESSEL = "CREW_CREW_CHANGE_CHANGE_PLAN_BY_VESSEL",
    CREW_CREW_CHANGE_CHANGE_PLAN_REPORT = "CREW_CREW_CHANGE_CHANGE_PLAN_REPORT",
    CREW_CREW_CHANGE_CREW_REPORT_STATUS = "CREW_CREW_CHANGE_CREW_REPORT_STATUS",
    CREW_CREW_CHANGE_CREW_MATRIX = "CREW_CREW_CHANGE_CREW_MATRIX",

    CREW_APPOINTMENT = "CREW_APPOINTMENT",
    CREW_APPOINTMENT_PERSONNEL_APPOINTMENT = "CREW_APPOINTMENT_PERSONNEL_APPOINTMENT",
    CREW_APPOINTMENT_INDIVIDUAL_REVISION = "CREW_APPOINTMENT_INDIVIDUAL_REVISION",
    CREW_APPOINTMENT_APPROVAL_OF_APPOINTMENT = "CREW_APPOINTMENT_APPROVAL_OF_APPOINTMENT",
    CREW_APPOINTMENT_SIGN_ON_OFF_REPORT = "CREW_APPOINTMENT_SIGN_ON_OFF_REPORT",
    CREW_APPOINTMENT_AWAITING_ORDERS = "CREW_APPOINTMENT_AWAITING_ORDERS",

    CREW_CERTIFICATE = "CREWCERTIFICATE",
    CREW_CERTIFICATE_CERTIFICATE_APPLICATION_LIST = "CREW_CERTIFICATE_CERTIFICATE_APPLICATION_LIST",

    CREW_WORK_REST_HOUR = "CREW_WORK_REST_HOUR",
    CREW_WORK_REST_HOUR_WORK_TIME_REPORT = "CREW_WORK_REST_HOUR_WORK_TIME_REPORT",
    CREW_WORK_REST_HOUR_OVERTIME_REPORTS = "CREW_WORK_REST_HOUR_OVERTIME_REPORTS",
    CREW_WORK_REST_HOUR_WORK_TIME_INQUIRY = "CREW_WORK_REST_HOUR_WORK_TIME_INQUIRY",

    CREW_CREW_STATUS = "CREW_CREW_STATUS",
    CREW_CREW_STATUS_CALCULATION_VACATION_DATE = "CREW_CREW_STATUS_CALCULATION_VACATION_DATE",
    CREW_CREW_STATUS_CREW_LIST_BY_DEPT_RANK_AND_VESSEL = "CREW_CREW_STATUS_CREW_LIST_BY_DEPT_RANK_AND_VESSEL",
    CREW_CREW_STATUS_ORGANIZATION_CHART = "CREW_CREW_STATUS_ORGANIZATION_CHART",
    CREW_CREW_STATUS_ID_NO_LIST = "CREW_CREW_STATUS_ID_NO_LIST",
    CREW_CREW_STATUS_CREW_VACATION_STATUS = "CREW_CREW_STATUS_CREW_VACATION_STATUS",

    CREW_SYSTEM_SETTING = "CREW_SYSTEM_SETTING",
    CREW_SYSTEM_SETTING_CODE = "CREW_SYSTEM_SETTING_CODE",

    CREW_SICKNESS_INJURY = "CREW_SICKNESS_INJURY",
    CREW_SICKNESS_INJURY_SICKNESS_INJURY_LIST = "CREW_SICKNESS_INJURY_SICKNESS_INJURY_LIST",
    CREW_SICKNESS_INJURY_SICKNESS_INJURY_REGISTER = "CREW_SICKNESS_INJURY_SICKNESS_INJURY_REGISTER",
    CREW_SICKNESS_INJURY_SICKNESS_INJURY_REPORT = "CREW_SICKNESS_INJURY_SICKNESS_INJURY_REPORT",

    CREW_EDUCATION_TRAINING = "CREW_EDUCATION_TRAINING",
    CREW_EDUCATION_TRAINING_TRAINING_COURSE = "CREW_EDUCATION_TRAINING_TRAINING_COURSE",
    CREW_EDUCATION_TRAINING_COMPLETION_STATUS_OF_TRAINING = "CREW_EDUCATION_TRAINING_COMPLETION_STATUS_OF_TRAINING",
    CREW_EDUCATION_TRAINING_TRAINING_APPOINTMENT = "CREW_EDUCATION_TRAINING_TRAINING_APPOINTMENT",
    CREW_EDUCATION_TRAINING_PDOS = "CREW_EDUCATION_TRAINING_PDOS",
    CREW_EDUCATION_TRAINING_OFF_SIGNER_INTERVIEW = "CREW_EDUCATION_TRAINING_OFF_SIGNER_INTERVIEW",

    CREW_EVALUATION_VESSEL_EVALUATION_STATUS = "CREW_EVALUATION_VESSEL_EVALUATION_STATUS",
    CREW_EVALUATION_MANAGE_OFF_SIGNER_EVALUATION = "CREW_EVALUATION_MANAGE_OFF_SIGNER_EVALUATION",
    CREW_EVALUATION_UPLOAD_APPRAISER_DATA = "CREW_EVALUATION_UPLOAD_APPRAISER_DATA",
    CREW_EVALUATION_UPLOAD_EVALUATION_RATING = "CREW_EVALUATION_UPLOAD_EVALUATION_RATING",
    CREW_EVALUATION_UPLOAD_SHORE_EVALUATION_RATING = "CREW_EVALUATION_UPLOAD_SHORE_EVALUATION_RATING",
    CREW_EVALUATION_CALCULATION_EVALUATION_RATING = "CREW_EVALUATION_CALCULATION_EVALUATION_RATING",
    CREW_EVALUATION_EVALUATION_MANAGE_BY_APPRAISEE = "CREW_EVALUATION_EVALUATION_MANAGE_BY_APPRAISEE",
    CREW_EVALUATION_EVALUATION_MANAGE_BY_APPRAISER = "CREW_EVALUATION_EVALUATION_MANAGE_BY_APPRAISER",
    CREW_EVALUATION_EVALUATION_TABLE = "CREW_EVALUATION_EVALUATION_TABLE",
    CREW_EVALUATION_JOB_EVALUATION_RESULT_TABLE = "CREW_EVALUATION_JOB_EVALUATION_RESULT_TABLE",
    CREW_EVALUATION_EVALUATION_SPECIAL_REMARK = "CREW_EVALUATION_EVALUATION_SPECIAL_REMARK",
    CREW_EVALUATION_EVALUATION_SUMMARY_BY_RANK = "CREW_EVALUATION_EVALUATION_SUMMARY_BY_RANK",
    CREW_EVALUATION_EVALUATION_SUMMARY_BY_PERSONAL_DATA = "CREW_EVALUATION_EVALUATION_SUMMARY_BY_PERSONAL_DATA",

    CREW_REPORT = "CREW_REPORT",
    CREW_REPORT_REPORT_LIST = "CREW_REPORT_REPORT_LIST",

    // Crew vessel
    CREW_CREW_INFORMATION = "CREW_CREW_INFORMATION",
    CREW_CREW_INFORMATION_CREW_INFORMATION = "CREW_CREW_INFORMATION_CREW_INFORMATION",
    CREW_CREW_INFORMATION_REQUEST_EDIT = "CREW_CREW_INFORMATION_REQUEST_EDIT",

    CREW_WORK_WORK_PATTERN = "CREW_WORK_WORK_PATTERN",
    CREW_WORK_WORKING_SCHEDULE = "CREW_WORK_WORKING_SCHEDULE",
    CREW_WORK_CREW_MAPPING = "CREW_WORK_CREW_MAPPING",
    CREW_WORK_WORK_TIME_RECORD = "CREW_WORK_WORK_TIME_RECORD",

    CREW_ACCOUNT = "CREW_ACCOUNT",
    CREW_ACCOUNT_ACCOUNT_MANUAL = "CREW_ACCOUNT_ACCOUNT_MANUAL",
    CREW_ACCOUNT_CASH_REQUEST = "CREW_ACCOUNT_CASH_REQUEST",
    CREW_ACCOUNT_INCOMINGS = "CREW_ACCOUNT_INCOMINGS",
    CREW_ACCOUNT_ONBOARD_PAY = "CREW_ACCOUNT_ONBOARD_PAY",
    CREW_ACCOUNT_OPERATING_EXPENSES = "CREW_ACCOUNT_OPERATING_EXPENSES",
    CREW_ACCOUNT_ACCOUNT_REPORT = "CREW_ACCOUNT_ACCOUNT_REPORT",

    CREW_CREW_EXCHANGE = "CREW_CREW_EXCHANGE",

    CREW_CREW_LIST = "CREW_CREW_LIST",
    CREW_CREW_LIST_CREW_LIST = "CREW_CREW_LIST_CREW_LIST",
    CREW_CREW_LIST_QUALIFICATION = "CREW_CREW_LIST_QUALIFICATION",

    CREW_HAND_OVER_REPORT = "CREW_HAND_OVER_REPORT",
    CREW_HAND_OVER_REPORT_HAND_OVER_REPORT = "CREW_HAND_OVER_REPORT_HAND_OVER_REPORT",

    CREW_CREW_MANAGEMENT = "CREW_CREW_MANAGEMENT",

    CREW_EVALUATION = "CREW_EVALUATION",
    CREW_EVALUATION_EVALUATION = "CREW_EVALUATION_EVALUATION",

    CREW_MASTER_DATA = "CREW_MASTER_DATA",
    CREW_MASTER_DATA_BASIC_CODE = "CREW_MASTER_DATA_BASIC_CODE",
    CREW_MASTER_DATA_SUPPLY_CODE = "CREW_MASTER_DATA_SUPPLY_CODE",
    CREW_MASTER_DATA_MEDICINE_SUPPLY_CODE = "CREW_MASTER_DATA_MEDICINE_SUPPLY_CODE",
    CREW_MASTER_DATA_ACCOUNT_CODE = "CREW_MASTER_DATA_ACCOUNT_CODE",
    CREW_MASTER_DATA_EXCHANGE_RATE = "CREW_MASTER_DATA_EXCHANGE_RATE",
    CREW_MASTER_DATA_EVALUATION_CODE = "CREW_MASTER_DATA_EVALUATION_CODE",

    CREW_PAYSLIP = "CREW_PAYSLIP",

    CREW_ALTERNATIVE_SERVICE = "CREW_ALTERNATIVE_SERVICE",
    CREW_ALTERNATIVE_SERVICE_STATUS = "CREW_ALTERNATIVE_SERVICE_STATUS",
    CREW_ALTERNATIVE_SERVICE_SERVICE_RECORDS = "CREW_ALTERNATIVE_SERVICE_SERVICE_RECORDS",

    // Wages
    WAGES = "WAGES",
    WAGES_BASIC_INFORMATION = "WAGES_BASIC_INFORMATION",
    WAGES_BASIC_INFORMATION_WAGE_SCALE = "WAGES_BASIC_INFORMATION_WAGE_SCALE",
    WAGES_BASIC_INFORMATION_WAGE_SCALE_UPLOAD = "WAGES_BASIC_INFORMATION_WAGE_SCALE_UPLOAD",
    WAGES_BASIC_INFORMATION_MIXED_CREW_VESSEL_STATE = "WAGES_BASIC_INFORMATION_MIXED_CREW_VESSEL_STATE",
    WAGES_BASIC_INFORMATION_ALLOWANCE_MANAGEMENT = "WAGES_BASIC_INFORMATION_ALLOWANCE_MANAGEMENT",
    WAGES_BASIC_INFORMATION_VESSEL_CALLING_ALLOWANCE = "WAGES_BASIC_INFORMATION_VESSEL_CALLING_ALLOWANCE",
    WAGES_BASIC_INFORMATION_UNION_DUE_VESSEL_STATE = "WAGES_BASIC_INFORMATION_UNION_DUE_VESSEL_STATE",

    WAGES_ACCOUNT = "WAGES_ACCOUNT",
    WAGES_ACCOUNT_ACCOUNT_REPORT = "WAGES_ACCOUNT_ACCOUNT_REPORT",
    WAGES_ACCOUNT_ACCOUNT_SUMMARY = "WAGES_ACCOUNT_ACCOUNT_SUMMARY",

    WAGES_SOCIAL_SECURITY = "WAGES_SOCIAL_SECURITY",
    WAGES_SOCIAL_SECURITY_NATIONAL_PENSION = "WAGES_SOCIAL_SECURITY_NATIONAL_PENSION",
    WAGES_SOCIAL_SECURITY_HEALTH_INSURANCE = "WAGES_SOCIAL_SECURITY_HEALTH_INSURANCE",
    WAGES_SOCIAL_SECURITY_PERSONAL_PENSION = "WAGES_SOCIAL_SECURITY_PERSONAL_PENSION",
    WAGES_SOCIAL_SECURITY_EMPLOYMENT_INSURANCE = "WAGES_SOCIAL_SECURITY_EMPLOYMENT_INSURANCE",
    WAGES_SOCIAL_SECURITY_FAMILY_EDUCATIONAL_EXPENSES = "WAGES_SOCIAL_SECURITY_FAMILY_EDUCATIONAL_EXPENSES",
    WAGES_SOCIAL_SECURITY_DATA_INTERFACE_TO_SMAT = "WAGES_SOCIAL_SECURITY_DATA_INTERFACE_TO_SMAT",

    WAGES_WAGE_PROCESS = "WAGES_WAGE_PROCESS",
    WAGES_WAGE_PROCESS_WAGE_CALCULATION = "WAGES_WAGE_PROCESS_WAGE_CALCULATION",
    WAGES_WAGE_PROCESS_WAGE_PERSONAL_DATA = "WAGES_WAGE_PROCESS_WAGE_PERSONAL_DATA",
    WAGES_WAGE_PROCESS_WAGE_DEDUCTION_BY_CREW = "WAGES_WAGE_PROCESS_WAGE_DEDUCTION_BY_CREW",
    WAGES_WAGE_PROCESS_WAGE_DEDUCTION_BY_VESSEL = "WAGES_WAGE_PROCESS_WAGE_DEDUCTION_BY_VESSEL",
    WAGES_WAGE_PROCESS_WAGE_DEDUCTION_UPLOAD = "WAGES_WAGE_PROCESS_WAGE_DEDUCTION_UPLOAD",
    WAGES_WAGE_PROCESS_ACTING_ALLOWANCE = "WAGES_WAGE_PROCESS_ACTING_ALLOWANCE",
    WAGES_WAGE_PROCESS_ON_BOARD_WAGE_CALCULATION = "WAGES_WAGE_PROCESS_ON_BOARD_WAGE_CALCULATION",
    WAGES_WAGE_PROCESS_TRANSFERRING_SLIPS = "WAGES_WAGE_PROCESS_TRANSFERRING_SLIPS",

    WAGES_WAGE_REPORT = "WAGES_WAGE_REPORT",
    WAGES_WAGE_REPORT_PAYROLL_BOOK = "WAGES_WAGE_REPORT_PAYROLL_BOOK",
    WAGES_WAGE_REPORT_PAY_SLIP = "WAGES_WAGE_REPORT_PAY_SLIP",
    WAGES_WAGE_REPORT_BANK_REMITTANCE_LIST = "WAGES_WAGE_REPORT_BANK_REMITTANCE_LIST",
    WAGES_WAGE_REPORT_WAGE_CHECK_LIST = "WAGES_WAGE_REPORT_WAGE_CHECK_LIST",
    WAGES_WAGE_REPORT_PERSONAL_WAGE_INQUIRY = "WAGES_WAGE_REPORT_PERSONAL_WAGE_INQUIRY",
    WAGES_WAGE_REPORT_LEGALLY_REQUIRED_LABOR_COST = "WAGES_WAGE_REPORT_LEGALLY_REQUIRED_LABOR_COST",
    WAGES_WAGE_REPORT_UNUSED_VACATION_PAY = "WAGES_WAGE_REPORT_UNUSED_VACATION_PAY",
    WAGES_WAGE_REPORT_ACTING_AND_MIXED_WAGE_CHECK_LIST = "WAGES_WAGE_REPORT_ACTING_AND_MIXED_WAGE_CHECK_LIST",
    WAGES_WAGE_REPORT_EDUCATIONAL_COMPENSATION_LIST = "WAGES_WAGE_REPORT_EDUCATIONAL_COMPENSATION_LIST",
    WAGES_WAGE_REPORT_ASSOCIATION_FUND = "WAGES_WAGE_REPORT_ASSOCIATION_FUND",
    WAGES_WAGE_REPORT_WAGE_DETAIL = "WAGES_WAGE_REPORT_WAGE_DETAIL",
    WAGES_WAGE_REPORT_INCOME_TAX_CALCULATION_BILL = "WAGES_WAGE_REPORT_INCOME_TAX_CALCULATION_BILL",
    WAGES_WAGE_REPORT_AVERAGE_WAGES = "WAGES_WAGE_REPORT_AVERAGE_WAGES",
    WAGES_WAGE_REPORT_SALARY_TAXATION_STATUS = "WAGES_WAGE_REPORT_SALARY_TAXATION_STATUS",
    WAGES_WAGE_REPORT_WAGE_BONUS_ANALYSIS = "WAGES_WAGE_REPORT_WAGE_BONUS_ANALYSIS",
    WAGES_WAGE_REPORT_PENSION_MANAGEMENT = "WAGES_WAGE_REPORT_PENSION_MANAGEMENT",

    WAGES_CASH_REQUEST = "WAGES_CASH_REQUEST",
    WAGES_CASH_REQUEST_CASH_REQUEST_LIST = "WAGES_CASH_REQUEST_CASH_REQUEST_LIST",

    WAGES_CODE = "WAGES_CODE",
    WAGES_CODE_EXCHANGE_RATE = "WAGES_CODE_EXCHANGE_RATE",
    WAGES_CODE_ACCOUNT_DATA = "WAGES_CODE_ACCOUNT_DATA",

    // Maintenance
    MAINTENANCE = "MAINTENANCE",
    MAINTENANCE_PMS = "MAINTENANCE_PMS",
    MAINTENANCE_PMS_PMS_LIST = "MAINTENANCE_PMS_PMS_LIST",
    MAINTENANCE_PMS_MACHINERY_CARD = "MAINTENANCE_PMS_MACHINERY_CARD",
    MAINTENANCE_PMS_RUNNING_HOUR = "MAINTENANCE_PMS_RUNNING_HOUR",
    MAINTENANCE_PMS_ACHIEVEMENT = "MAINTENANCE_PMS_ACHIEVEMENT",

    MAINTENANCE_PLAN_RESULT = "MAINTENANCE_PLAN_RESULT",
    MAINTENANCE_PLAN_RESULT_RM_RECORD_LIST = "MAINTENANCE_PLAN_RESULT_RM_RECORD_LIST",

    MAINTENANCE_MASTER_DATA = "MAINTENANCE_MASTER_DATA",
    MAINTENANCE_MASTER_DATA_MACHINERY_EQUIPMENT_CODE = "MAINTENANCE_MASTER_DATA_MACHINERY_EQUIPMENT_CODE",
    MAINTENANCE_MASTER_DATA_VESSEL_MACHINERY_EQUIPMENT_CODE = "MAINTENANCE_MASTER_DATA_VESSEL_MACHINERY_EQUIPMENT_CODE",
    MAINTENANCE_MASTER_DATA_MAKER_MODEL_TYPE_MANAGEMENT = "MAINTENANCE_MASTER_DATA_MAKER_MODEL_TYPE_MANAGEMENT",

    EWMS = "EWMS",
    EWMS_GOODS_RECEIPT = "EWMS_GOODS_RECEIPT",
    EWMS_GOODS_RECEIPT_SUPPLY = "EWMS_GOODS_RECEIPT_SUPPLY",
    EWMS_GOODS_RECEIPT_VESSEL = "EWMS_GOODS_RECEIPT_VESSEL",

    EWMS_MOVEMENT_ORDER = "EWMS_MOVEMENT_ORDER",
    EWMS_MOVEMENT_ORDER_SUPPLY = "EWMS_MOVEMENT_ORDER_SUPPLY",
    EWMS_MOVEMENT_ORDER_VESSEL = "EWMS_MOVEMENT_ORDER_VESSEL",
    EWMS_MOVEMENT_ORDER_CANCEL = "EWMS_MOVEMENT_ORDER_CANCEL",
    EWMS_MOVEMENT_ORDER_HISTORY = "EWMS_MOVEMENT_ORDER_HISTORY",

    EWMS_GOODS_RELEASE = "EWMS_GOODS_RELEASE",
    EWMS_GOODS_RELEASE_SUPPLY = "EWMS_GOODS_RELEASE_SUPPLY",
    EWMS_GOODS_RELEASE_VESSEL = "EWMS_GOODS_RELEASE_VESSEL",

    EWMS_HISTORY_INFO = "EWMS_HISTORY_INFO",
    EWMS_HISTORY_INFO_HISTORY = "EWMS_HISTORY_INFO_HISTORY",
    EWMS_ADMIN_CONFIG = "EWMS_ADMIN_CONFIG",

    EWMS_BOARD = "EWMS_BOARD",
    EWMS_BOARD_NOTICE = "EWMS_BOARD_NOTICE",
    EWMS_BOARD_FAQ = "EWMS_BOARD_FAQ",
    EWMS_BOARD_NEWS = "EWMS_BOARD_NEWS",
    EWMS_BOARD_QA = "EWMS_BOARD_QA",

    EWMS_SUPPORT = "EWMS_SUPPORT",
    EWMS_SUPPORT_VESSEL_INFORMATION = "EWMS_SUPPORT_VESSEL_INFORMATION",

    PROCUREMENT_E_SUPPLY_PORTAL_EWMS = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_GOODS_RECEIPT_SUPPLY = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_GOODS_RECEIPT_SUPPLY",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_GOODS_RECEIPT_VESSEL = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_GOODS_RECEIPT_VESSEL",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_MOVEMENT_ORDER_SUPPLY = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_MOVEMENT_ORDER_SUPPLY",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_MOVEMENT_ORDER_VESSEL = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_MOVEMENT_ORDER_VESSEL",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_MOVEMENT_ORDER_CANCEL = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_MOVEMENT_ORDER_CANCEL",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_MOVEMENT_ORDER_HISTORY = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_MOVEMENT_ORDER_HISTORY",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_GOODS_RELEASE_SUPPLY = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_GOODS_RELEASE_SUPPLY",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_GOODS_RELEASE_VESSEL = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_GOODS_RELEASE_VESSEL",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_HISTORY_INFO_HISTORY = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_HISTORY_INFO_HISTORY",
    PROCUREMENT_E_SUPPLY_PORTAL_EWMS_ADMIN_WAREHOUSE = "PROCUREMENT_E_SUPPLY_PORTAL_EWMS_ADMIN_WAREHOUSE",

    PROCUREMENT_E_SUPPLY_PORTAL_EPRO_BID_PROCUREMENT_BID_LIST = "PROCUREMENT_E_SUPPLY_PORTAL_EPRO_BID_PROCUREMENT_BID_LIST",
    PROCUREMENT_E_SUPPLY_PORTAL_EPRO_BID_PROCUREMENT_BID_LOG_VIEW = "PROCUREMENT_E_SUPPLY_PORTAL_EPRO_BID_PROCUREMENT_BID_LOG_VIEW",
    PROCUREMENT_E_SUPPLY_PORTAL_EPRO_BID_FLEET_BID_LIST = "PROCUREMENT_E_SUPPLY_PORTAL_EPRO_BID_FLEET_BID_LIST",
    PROCUREMENT_E_SUPPLY_PORTAL_EPRO_BID_FLEET_BID_LOG_VIEW = "PROCUREMENT_E_SUPPLY_PORTAL_EPRO_BID_FLEET_BID_LOG_VIEW",

    EPRO = "EPRO",
    EPRO_BID_PROCUREMENT = "EPRO_BID_PROCUREMENT",

    EPRO_BID_PROCUREMENT_BID_LIST = "EPRO_BID_PROCUREMENT_BID_LIST",
    EPRO_BID_PROCUREMENT_BID_LOG_VIEW = "EPRO_BID_PROCUREMENT_BID_LOG_VIEW",

    EPRO_BID_FLEET = "EPRO_BID_FLEET",
    EPRO_BID_FLEET_BID_LIST = "EPRO_BID_FLEET_BID_LIST",
    EPRO_BID_FLEET_BID_LOG_VIEW = "EPRO_BID_FLEET_BID_LOG_VIEW",

    EPRO_BID_SUPPLIER = "EPRO_BID_SUPPLIER",
    EPRO_BID_SUPPLIER_BID_LIST = "EPRO_BID_SUPPLIER_BID_LIST",

    EPRO_BID_APPROVAL_SUBMIT_TEST = "EPRO_BID_APPROVAL_SUBMIT_TEST",

    EPRO_ADMIN = "EPRO_ADMIN",

    EPRO_BOARD = "EPRO_BOARD",
    EPRO_BOARD_NOTICE = "EPRO_BOARD_NOTICE",
    EPRO_BOARD_FAQ = "EPRO_BOARD_FAQ",
    EPRO_BOARD_NEWS = "EPRO_BOARD_NEWS",
    EPRO_BOARD_QA = "EPRO_BOARD_QA",

    OPERATION = "OPERATION",
    OPERATION_OPEX_EXPENSE_SETTLEMENT = "OPERATION_OPEX_EXPENSE_SETTLEMENT",
    OPERATION_OPEX_EXPENSE_SETTLEMENT_ENTRY = "OPERATION_OPEX_EXPENSE_SETTLEMENT_ENTRY",
    OPERATION_OPEX_EXPENSE_SETTLEMENT_REVERSE = "OPERATION_OPEX_EXPENSE_SETTLEMENT_REVERSE",

    OPERATION_OPEX_INQUIRY = "OPERATION_OPEX_INQUIRY",
    OPERATION_OPEX_INQUIRY_ACCOUNT = "OPERATION_OPEX_INQUIRY_ACCOUNT",

    OPERATION_OPEX_ADVANCE_PAYMENT = "OPERATION_OPEX_ADVANCE_PAYMENT",
    OPERATION_OPEX_ADVANCE_PAYMENT_REQUEST = "OPERATION_OPEX_ADVANCE_PAYMENT_REQUEST",
    OPERATION_OPEX_ADVANCE_PAYMENT_CANCEL = "OPERATION_OPEX_ADVANCE_PAYMENT_CANCEL",
}
