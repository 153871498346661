<template>
  <el-dropdown trigger="click" class="h-full" @command="onCommand">
    <div class="flex items-center gap-6 md:bg-gray-100 h-full !pl-6 !pr-8 md:border-l">
      <UserIcon />
      <div class="hidden md:flex items-center gap-6">
        <div>
          <div>{{ $t('common.messages.welcome') }}</div>
          <div>{{ name }}</div>
        </div>
        <el-icon class="el-icon--right">
          <Icon icon="carbon:chevron-down" />
        </el-icon>
      </div>
    </div>
    <template #dropdown>
      <el-dropdown-menu class="min-w-60">
        <el-dropdown-item command="/profile">
          <i class="mdi mdi-account-outline mr-10"></i> Profile
        </el-dropdown-item>
        <el-dropdown-item command="/calendar">
          <i class="mdi mdi-calendar mr-10"></i> Calendar
        </el-dropdown-item>
        <el-dropdown-item command="/contacts">
          <i class="mdi mdi-account-multiple-outline mr-10"></i> Contacts
        </el-dropdown-item>
        <el-dropdown-item command="/logout" divided>
          <i class="mdi mdi-logout mr-10"></i> Logout
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <ProfilePopup :visible="showProfileModal" @close="showProfileModal = false" />
  <ConfirmPopup
    :visible="isLogoutPopupVisible"
    :title="$t('common.buttons.logout')"
    :message="$t('common.messages.logoutConfirm')"
    :btn-title="$t('common.buttons.logout')"
    @close="isLogoutPopupVisible = false"
    @submit="confirmLogout"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Icon } from '@iconify/vue'
import { RouteLocationRaw, useRouter } from 'vue-router'

import ConfirmPopup from 'ui/src/components/ConfirmPopup.vue'

import UserIcon from '@/assets/icons/user.svg?component'
import { globalI18n } from '@/locales'
import ProfilePopup from '@/views/pages/ProfilePopup.vue'

defineProps({
  name: {
    type: String,
    default: 'Admin',
  },
})

// Refs
const isLogoutPopupVisible = ref(false)
const showProfileModal = ref<boolean>(false)
const router = useRouter()

const onCommand = (command: RouteLocationRaw) => {
  if (command === '/profile') {
    handleClickProfile()
  } else if (command === '/logout') {
    // if (confirm(globalI18n.t('message.logoutConfirm'))) {
    //   router.push('/logout')
    // }
    isLogoutPopupVisible.value = true
    return
  } else {
    router.push(`/${command}`)
  }
}

const handleClickProfile = () => {
  showProfileModal.value = true
}

const confirmLogout = () => {
  isLogoutPopupVisible.value = false
  router.push('/logout')
}
</script>
