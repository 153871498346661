import { SubjectAbility } from 'shared/types/ability'
import { defineAsyncComponentWithOptions } from 'shared/utils'
import { KainosMessageByModule } from 'shared/utils/constants'
import layouts from 'shared/utils/layout'

const MovementPlanSupply = defineAsyncComponentWithOptions(
  () => import('ewmsMovement/MovementPlanSupply.vue')
)
const MovementPlanVessel = defineAsyncComponentWithOptions(
  () => import('ewmsMovement/MovementPlanVessel.vue')
)
const MovementCancel = defineAsyncComponentWithOptions(
  () => import('ewmsMovement/MovementCancel.vue')
)
const MovementHistory = defineAsyncComponentWithOptions(
  () => import('ewmsMovement/MovementHistory.vue')
)

const ewmsMovementRouter = [
  {
    path: '/ewms/movement-order/movement-plan-supply',
    name: SubjectAbility.EWMS_MOVEMENT_ORDER_SUPPLY,
    component: MovementPlanSupply,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      searchable: true,
      title: 'order',
      locales: [KainosMessageByModule.EWMS, KainosMessageByModule.COMMON],
      breadCrumbs: [{ text: 'ewms' }, { text: 'movementOrder' }, { text: 'order', isActive: true }],
    },
  },
  {
    path: '/ewms/movement-order/movement-history',
    name: SubjectAbility.EWMS_MOVEMENT_ORDER_HISTORY,
    component: MovementHistory,
    meta: {
      auth: true,
      layout: layouts.navLeft,
      searchable: true,
      title: 'movementHistory',
      locales: [KainosMessageByModule.EWMS, KainosMessageByModule.COMMON],
      breadCrumbs: [
        { text: 'ewms' },
        { text: 'movementOrder' },
        { text: 'movementHistory', isActive: true },
      ],
    },
  },
]

export default ewmsMovementRouter
