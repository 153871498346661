import { App } from 'vue'
import { abilitiesPlugin, Can } from '@casl/vue'

import { ability } from './ability'

export const useCasl = (app: App) => {
  app.use(abilitiesPlugin, ability, { useGlobalProperties: true })
  app.component(Can.name, Can)
  app.provide('$ability', app.config.globalProperties.$ability)
}
