<template>
  <el-dialog v-model="isVisible" class="!min-w-[850px] !max-w-[950px]" @close="handleClose">
    <template #header>
      <div class="custom-dialog-header">Profile</div>
    </template>
    <content-layout>
      <template #content-header>
        <div class="flex items-center justify-between">
          <span class="text-[18px] text-black font-bold">Company Information</span>
          <div class="flex items-center gap-2">
            <el-button type="secondary" size="large" @click="handleChangePassword">
              Change Password
            </el-button>
            <el-button type="primary" size="large" @click="handleSave"> Save </el-button>
          </div>
        </div>
      </template>
      <template #content>
        <profile-content
          :company-profile="companyProfile"
          @getCompanyProfile="handleUpdatedCompanyProfile"
        />
      </template>
      <template #footer>
        <el-button type="secondary" @click="handleClose">
          {{ $t('common.buttons.close') }}
        </el-button>
      </template>
    </content-layout>
  </el-dialog>
  <ChangePasswordPopup
    :visible="showChangePassword"
    :user-id="userId"
    @close="showChangePassword = false"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import { CACHE, getCookie } from 'shared'

import { successAlert } from '@/shared/utils'
import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'
import { Profile } from '@/types'
import ProfileContent from '@/views/components/ProfileContent.vue'

import ChangePasswordPopup from './authentication/ChangePasswordPopup.vue'

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
})

// Emits
const emit = defineEmits(['submit', 'close'])

// Stores
const authStore = useAuthStore()
const mainStore = useMainStore()
// Refs
const isVisible = ref<boolean>(false)
const showChangePassword = ref<boolean>(false)
const userId = ref<string>('')
const companyProfile = ref<Profile>(null)
const companyProfileUpdate = ref<Profile>(null)

// Watch
watch(
  () => props.visible,
  (newVal) => {
    isVisible.value = newVal
  }
)

// Hooks
onMounted(() => {
  const userInfo = getCookie(CACHE.USER_INFO) ?? null
  userId.value = userInfo.id
  getCompanyProfile(userInfo.vmsCompanyCode)
})

// Functions
const emitEvents = () => {
  emit('submit')
  emit('close')
}

const handleClose = () => {
  emitEvents()
}

const handleChangePassword = () => {
  showChangePassword.value = true
}

const handleSave = async () => {
  mainStore.showLoading()
  try {
    await authStore.updateCompanyProfile(
      companyProfileUpdate.value.companyCode,
      companyProfileUpdate.value
    )
    handleClose()
    successAlert('common.messages.success')
    mainStore.hideLoading()
  } catch (error) {}
}

const getCompanyProfile = async (companyCode: string) => {
  const data = await authStore.companyProfile(companyCode)
  companyProfile.value = data
}

const handleUpdatedCompanyProfile = (payload) => {
  companyProfileUpdate.value = payload
}
</script>
