<template>
  <transition name="fade">
    <div v-if="loading" class="loading-overlay">
      <div class="content">
        <img
          class="logo"
          src="../../assets/images/hmm_progressBar5.gif"
          alt="logo"
        />
      </div>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { watch } from "vue";

defineOptions({
  name: "LoadingView",
});

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
});

watch(
  () => props.loading,
  (loading) => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  },
);
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    .logo {
      width: 110px;
      height: 110px;
      margin-bottom: 20px;
      padding: 15px;
      background: white;
      border-radius: 15px;
    }
  }
}

.ripple {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 2px solid;
  background-color: transparent;
  opacity: 0;

  width: calc(13px * var(--circle-index));
  height: calc(13px * var(--circle-index));
  border-color: hsl(calc((var(--circle-index) - 1) * 180), 100%, 70%);
  animation: ripple-effect 1s infinite calc(0.3s * var(--circle-index));
}

@keyframes ripple-effect {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
</style>
