<template>
  <div class="splash-screen">
    <div class="wrap">
      <img class="loading-img" src="/hmm_progressBar5.gif" alt="loading" />
    </div>
  </div>
</template>

<style lang="scss">
.splash-screen {
  background: #d3e2ee;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
}

.splash-screen > .wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.splash-screen > .wrap > img {
  display: block;
  width: 175px;
  padding: 25px;
  background: white;
  border-radius: 25px;
}

.splash-screen > .wrap > img.logo {
  -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
}
</style>
