<template>
  <el-dialog
    v-model="isVisible"
    :title="$t('common.titles.changePassword')"
    width="750px"
    @close="emit('close')"
  >
    <div class="flex flex-col p-3 gap-y-2">
      <div class="flex flex-col justify-start pl-3 pb-3">
        <p>{{ $t('common.messages.recommendChangePassword') }}</p>
        <p>- {{ $t('common.messages.passwordMustBeChangedAfter90Days') }}</p>
        <p>- {{ $t('common.messages.passwordEntryErrorMoreThan5Times') }}</p>
        <p>- {{ $t('common.messages.passwordMustBeCombination') }}</p>
        <p>- {{ $t('common.messages.passwordMustBeAtLeast8Digits') }}</p>
      </div>
      <el-descriptions :column="1" size="small" border>
        <el-descriptions-item label="ID" label-class-name="w-2/5" label-align="right">
          <el-input v-model="authStore.userInfo.id" placeholder="ID" readonly />
        </el-descriptions-item>
        <el-descriptions-item
          :label="$t('common.labels.currentPassword')"
          label-align="right"
          label-class-name="w-2/5"
        >
          <el-input
            v-model="form.currentPassword"
            type="password"
            show-password
            :placeholder="$t('common.labels.password')"
          />
        </el-descriptions-item>
        <el-descriptions-item
          :label="$t('common.labels.newPassword')"
          label-align="right"
          label-class-name="w-2/5"
        >
          <el-input
            v-model="form.newPassword"
            type="password"
            show-password
            :placeholder="$t('common.labels.password')"
          />
        </el-descriptions-item>
        <el-descriptions-item
          :label="$t('common.labels.confirmPassword')"
          label-align="right"
          label-class-name="w-2/5"
        >
          <el-input
            v-model="form.confirmPassword"
            type="password"
            show-password
            :placeholder="$t('common.labels.password')"
          />
        </el-descriptions-item>
      </el-descriptions>
      <div class="flex justify-end gap-x-1">
        <el-button class="secondary" @click="emit('close')">{{
          $t('common.buttons.close')
        }}</el-button>
        <el-button type="primary" @click="handleSubmit">{{ $t('common.buttons.ok') }}</el-button>
      </div>
    </div>
    <ErrorMessagePopup
      :visible="isShowErrorMessagePopup"
      :title="$t('common.messages.passwordChangeFailed')"
      :message="$t(messageError)"
      @close="handleCloseErrorMessagePopup"
    />
  </el-dialog>
</template>

<script setup lang="ts">
// Import vue -> external -> component -> dev -> local (alphabet) -> @types
import { ref, watch } from 'vue'

import ErrorMessagePopup from 'ui/src/custom-components/ErrorMessagePopup.vue'

import { globalI18n } from '@/locales'
import { AuthService } from '@/services/auth'
import { notifySuccess } from '@/shared/utils'
import { useAuthStore } from '@/stores/auth'

defineOptions({
  name: 'ChangePasswordPopup',
})

// Emits
const emit = defineEmits(['close'])

// Props
const props = defineProps<{
  visible: boolean
}>()

// Constants

// Stores
const authStore = useAuthStore()

// Services
const authService = new AuthService()

// Composables

// Refs
const isVisible = ref(false)
const form = ref({
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
})
const isShowErrorMessagePopup = ref(false)
const messageError = ref('')
// Computed

// Reactive

// Watch
watch(
  () => props.visible,
  async (value) => {
    isVisible.value = value
  }
)
// Hooks

// Functions

const validatePassword = (password: string) => {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
  if (!regex.test(password)) {
    messageError.value = 'common.errors.thePasswordInvalid'
    isShowErrorMessagePopup.value = true
    return false
  }
  return true
}

const handleSubmit = () => {
  if (form.value.newPassword === '') {
    messageError.value = 'common.errors.theNewPassNotAccurately'
    isShowErrorMessagePopup.value = true
    return
  }
  if (form.value.currentPassword === form.value.newPassword) {
    messageError.value = 'common.errors.theNewPassNotAccurately'
    isShowErrorMessagePopup.value = true
    return
  }
  if (form.value.newPassword !== form.value.confirmPassword) {
    messageError.value = 'common.errors.oldAndNewPasswordNotMatch'
    isShowErrorMessagePopup.value = true
    return
  }
  if (!validatePassword(form.value.newPassword)) {
    isShowErrorMessagePopup.value = true
    return
  }

  authService.changePassword(form.value).then(() => {
    notifySuccess(globalI18n.t('common.messages.passwordChangeComplete'))
    emit('close')
  })
}

const handleCloseErrorMessagePopup = () => {
  isShowErrorMessagePopup.value = false
}

// Expose
</script>
