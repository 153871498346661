/**
 * use component
 * 
 * This component is a NoticePopup that displays a list of notices in a dialog.
 * 
 * ## Usage
 * 
 * 1. Import the component in your Vue file:
 * 
 * ```javascript
 * import NoticePopup from '@/views/components/NoticePopup.vue'
 * ```
 * 
 * 2. Add the component to your template:
 * 
 * ```vue
 * <NoticePopup ref="noticePopup" />
 * ```
 * 
 * 3. define noti ref for NoticePopup
 * 
 * ```javascript
 * const noticePopup = ref(null)
 * ```
 * 
 * 4. To show the NoticePopup, call the `showNoticePopup` method:
 * 
 * ```javascript
 * noticePopup.value.showNoticePopup()
 * ```
 */

<template>
  <el-dialog
    v-model="visible"
    @close="visible = false"
    width="80%"
    class="-top-4"
    :title="$t('common.labels.notice')"
  >
    <div class="h-[66vh]">
      <data-table
        :data="data"
        :columns="columns"
        :page-size="pagination.pageSize"
        :current-page="pagination.pageNumber + 1"
        :total="pagination.totalCount"
        highlight-current-row
        class="!h-full"
        @size-change="handleSizeChange"
        @page-change="handleChangePage"
      >
        <template #transmitYmd="{ row }">
          {{ formatDate(row?.transmitYmd, FORMAT_DATE_TIME.DATE) }}
        </template>
      </data-table>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
// Import vue -> external -> component -> dev -> local (alphabet) -> @types
import { computed, onMounted, ref } from 'vue'
import { formatDate } from 'shared/utils'
import { FORMAT_DATE_TIME } from 'shared/utils/constants'

import { MODULE_CODES } from '@/shared/constants'
import { useCommonStore } from '@/stores/commons'
import { getNoticeParameter } from '@/types'

defineOptions({
  name: 'NoticePopup',
})

// Emits
const emit = defineEmits(['close'])

// Props

// Constants
const columns = [
  {
    key: 'titleName',
    label: 'common.labels.title',
    minWidth: '200px',
    align: 'left',
  },
  {
    key: 'transmitYmd',
    label: 'common.labels.postedDate',
  },
]

// Stores
const commonStore = useCommonStore()

// Composables

// Refs
const visible = ref(false)

// Computed
const data = computed(() => commonStore.noticeRes.data)
const pagination = computed(() => commonStore.noticeRes.page)

// Reactive

// Hooks

// Functions

const getData = (params: getNoticeParameter) => {
  params.page ??= pagination.value.pageNumber
  params.size ??= pagination.value.pageSize
  params.boardType ??= 'NOTICE'
  params.moduleCode ??= MODULE_CODES.EWMS
  commonStore.getNotices(params)
}
const showNoticePopup = () => {
  visible.value = true
}
const handleSizeChange = (val: number) => {
  getData({ page: 0, size: val })
}

const handleChangePage = (page: number) => {
  getData({ page: page - 1 })
}

onMounted(() => getData({}))
// Expose
defineExpose({
  showNoticePopup,
})
</script>
<style lang="scss" scoped></style>
