<template>
  <el-dialog
    v-model="isVisible"
    :title="props.titlePopup ?? ''"
    width="25%"
    class="hide-header !rounded-lg"
    @close="$emit('close')"
  >
    <div class="wrap-view">
      <div class="items-center m-1 pt-2 pb-1">
        <div class="text-black px-2 text-center mb-1 py-2">
          <h4
            v-if="props.title"
            class="notify-mess font-extrabold accent-text pb-4"
          >
            {{ props.title }}
          </h4>
          <span v-if="props.message" class="notify-info">{{
            props.message
          }}</span>
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-x-1">
      <el-button class="secondary" @click="handleClose">{{
        $t("common.buttons.close")
      }}</el-button>
      <el-button type="danger" @click="handleSubmit">{{
        props.btnTitle
      }}</el-button>
    </div>
  </el-dialog>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";

const emit = defineEmits(["close", "submit"]);

const props = defineProps({
  visible: Boolean,
  titlePopup: String,
  title: String,
  message: String,
  btnType: String,
  btnTitle: String,
});

const isVisible = ref(false);
const handleClose = () => {
  emit("close");
};

const handleSubmit = () => {
  emit("submit");
};

// Watch
watch(props, (newProps) => {
  if (newProps.visible !== isVisible.value) {
    isVisible.value = newProps.visible;
  }
});
</script>

<style scoped lang="scss">
@import "ui/assets/scss/_variables";
</style>
