<template>
  <div class="flex flex-col p-4 gap-y-4">
    <div class="flex items-center justify-center gap-x-3">
      <p class="text-gray-500">{{ $t('common.messages.welcome') }}</p>
      <p>{{ username }}</p>
    </div>
    <el-button-group class="w-full px-5">
      <el-button size="large" class="secondary w-1/2" @click="onCommand('/profile')">{{
        $t('common.menus.profile')
      }}</el-button>
      <el-button size="large" class="secondary w-1/2" @click="onCommand('/logout')">{{
        $t('common.buttons.logout')
      }}</el-button>
    </el-button-group>
  </div>
</template>

<script setup lang="ts">
// Import vue -> external -> component -> dev -> local (alphabet) -> @types
import { computed } from 'vue'
import { getTextByLanguage } from 'shared/utils/language-helpers'
import { RouteLocationRaw, useRouter } from 'vue-router'

import { globalI18n } from '@/locales'
import { useAuthStore } from '@/stores/auth'

defineOptions({
  name: 'UserNav',
})

// Emits

// Props
defineProps({
  name: {
    type: String,
    default: 'Admin',
  },
})

// Constants
const router = useRouter()

// Stores
const authStore = useAuthStore()

// Composables

// Refs

// Computed
const username = computed(() => {
  const userInfo = authStore.getUserInfo
  return getTextByLanguage(
    globalI18n.locale,
    userInfo?.employeeEnglishName,
    userInfo?.employeeKoreanName
  )
})

// Reactive

// Watch

// Hooks

// Functions
const onCommand = (command: RouteLocationRaw) => {
  if (command === '/logout') {
    confirm(globalI18n.t('message.logoutConfirm'))
    router.push(command)
    return
  }
  router.push(command)
}

// Expose
</script>
