import isEmpty from "lodash/isEmpty";
import { languageTypeCode } from "../types";

export const getTextByLanguage = (
    locale: string,
    englishText: string,
    koreaText: string,
) => {
    if (isEmpty(englishText)) {
        return koreaText;
    }
    if (isEmpty(koreaText)) {
        return englishText;
    }
    return locale === languageTypeCode.EN ? englishText : `${koreaText}`;
};
