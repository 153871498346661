<template>
  <AuthLayout v-if="!isLoggedMain" />
  <MainLayout v-else />
  <LoadingView :loading="isLoading" />
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

import LoadingView from 'ui/src/components/LoadingView.vue'

import { useAuthStore } from '@/stores/auth'

import AuthLayout from './layouts/AuthLayout.vue'
import MainLayout from './layouts/MainLayout.vue'
import { useCommonStore } from './stores/commons'
import { useMainStore } from './stores/main'

const isLoggedMain = computed(() => {
  return useAuthStore().isLogged
})

const isLoading = computed(() => {
  return useMainStore().isLoading
})

onMounted(() => {
  // Initialize common datas
  if (isLoggedMain.value) {
    useCommonStore().initCommonData()
  }
})
</script>

<style lang="scss" scoped>
@import 'ui/assets/scss/app';
</style>
