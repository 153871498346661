<template>
  <div class="main-page-container gap-3 pl-2">
    <el-row class="h-full w-full" :gutter="20">
      <el-col :span="18">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="bg-content h-full flex-col">
              <div class="flex justify-center slogan flex-col">
                <strong>HMM e-WMS</strong>
                <p class="mt-3">
                  Management Warehousing of Materials<br />
                  Reduction for Purchasing Lead Time<br />
                  Inquiry of Vessel Operation Schedule
                </p>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="flex flex-wrap justify-between gap-4">
              <div v-for="cate of userSupport" :key="cate.id" class="cate w-1/2" :class="cate.id">
                <div class="cate-container flex justify-between align-center p-1">
                  <div class="cate-globe p-1 rounded-full flex justify-center align-center">
                    <Icon icon="mynaui:globe" width="26" height="26" class="text-white" />
                  </div>
                  <div class="flex flex-col whitespace-nowrap">
                    <div class="cate-label">
                      <div v-if="cate.subLabel" class="text-xs">{{ cate.subLabel }}</div>
                    </div>
                    <div class="text-lg w-1/2 whitespace-nowrap">{{ cate.label }}</div>
                  </div>
                </div>
                <div
                  class="cate-count text-lg font-black cursor-pointer"
                  @click="handleGoPage(cate.id)"
                >
                  {{ cate.count !== '' ? Number(cate.count).toLocaleString() : '' }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="mt-4">
          <el-col :span="12">
            <div class="lastest-information round-box new">
              <div class="align-end">
                <div class="flex justify-between mb-1 title">
                  <div class="text-[#202857] font-black text-lg p-1">Supply Item List</div>
                  <div class="flex items-center">
                    <Icon
                      v-show="false"
                      icon="iconamoon:history-fill"
                      width="16"
                      height="16"
                      class="mr-2"
                      @click="handleReload"
                    />
                    <div class="more-btn" @click="handleMorePage">More +</div>
                  </div>
                </div>
                <div class="p-3 pb-1" style="display: none">
                  <div class="flex flex-wrap items-center border-b">
                    <el-button
                      v-for="(tab, index) in userSupportTab"
                      v-show="index === 0"
                      :key="tab"
                      class="tab-item-control"
                      @click="changeTab(tab.id)"
                    >
                      <div class="custom-tab-control" :class="{ action: activeTab == tab.id }">
                        {{ tab.label }}
                      </div>
                    </el-button>
                  </div>
                </div>
              </div>

              <div class="list-content pr-2">
                <div v-if="isLoadingLastestInfo" class="loading">
                  <Icon
                    icon="line-md:loading-loop"
                    width="36"
                    height="36"
                    class="text-[#202857] ml-2"
                  />
                </div>
                <div
                  v-if="lastestInfo[activeTab] && lastestInfo[activeTab].length > 0"
                  class="p-4 pt-1"
                >
                  <!-- 헤더 -->
                  <div
                    v-for="item of lastestInfo[activeTab]"
                    :key="item.sysRowIndex"
                    class="flex pr-2 pl-2 hover:bg-gray-100"
                  >
                    <div
                      class="flex justify-between item cursor-pointer"
                      @dblclick="handleItemClick(item)"
                    >
                      <span class="w-[4ch] text-left font-black">{{ item.viewVesselCode }}</span>
                      <span class="flex-1 text-left truncate mx-4">{{ item.supplierName }}</span>
                      <span class="w-[10ch] text-right date">{{ item.viewYmd }}</span>
                    </div>
                  </div>
                </div>
                <div v-else class="flex items-center justify-center h-full">
                  <p style="color: #adaeb1">No items available.</p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="lastest-information round-box new">
              <div class="align-end">
                <div class="flex justify-between mb-1 title">
                  <div class="text-[#202857] font-black text-lg p-1">Movement Item List</div>
                  <div class="more-btn" @click="handleOtherMorePage">More +</div>
                </div>
                <div class="p-3 pb-1" style="display: none">
                  <div class="flex flex-wrap items-center border-b">
                    <el-button
                      v-for="(tab, index) in userSupportOtherTab"
                      v-show="index === 1"
                      :key="tab"
                      class="tab-item-control"
                      @click="changeOtherTab(tab.id)"
                    >
                      <div class="custom-tab-control" :class="{ action: activeOtherTab == tab.id }">
                        {{ tab.label }}
                      </div>
                    </el-button>
                  </div>
                </div>
              </div>

              <div class="list-content pr-2">
                <div v-if="isLoadingLastestInfo" class="loading">
                  <Icon
                    icon="line-md:loading-loop"
                    width="36"
                    height="36"
                    class="text-[#202857] ml-2"
                  />
                </div>
                <div
                  v-if="lastestInfo[activeOtherTab] && lastestInfo[activeOtherTab].length > 0"
                  v-show="activeOtherTab === userSupportOtherTab[1].id"
                  class="p-4 pt-1"
                >
                  <!-- 헤더 -->
                  <div
                    v-for="item of lastestInfo[activeOtherTab]"
                    :key="item.sysRowIndex"
                    class="flex pr-2 pl-2 hover:bg-gray-100"
                  >
                    <div
                      class="flex justify-between item cursor-pointer"
                      @dblclick="handleOtherItemClick(item)"
                    >
                      <span class="w-[4ch] text-left font-black">{{ item.viewVesselCode }}</span>
                      <span class="flex-1 text-left truncate mx-4">{{ item.supplierName }}</span>
                      <span class="w-[10ch] text-right date">{{ item.viewYmd }}</span>
                    </div>
                  </div>
                </div>
                <div v-else class="flex items-center justify-center h-full">
                  <p style="color: #adaeb1">No items available.</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <div class="notice round-box">
          <div class="flex justify-between title">
            <div class="text-[#202857] font-black text-lg p-1">Notice</div>
            <!-- <div class="more-btn" @click="handleShowPopup">More +</div> -->
            <div class="more-btn" @click="handleNoticePage()">More +</div>
          </div>
          <div class="list-content p-4">
            <div v-if="isLoading" class="loading">
              <Icon
                icon="line-md:loading-loop"
                width="36"
                height="36"
                class="text-[#202857] ml-2"
              />
            </div>
            <div
              v-for="item of notices"
              v-else
              :key="item.boardSequence"
              class="flex pr-2 pl-2 hover:bg-gray-100 cursor-pointer"
              @click="handleBoardRowDbClick(item)"
            >
              <div class="flex justify-between item">
                <span class="list">{{ item.titleName }}</span>
                <span class="date">{{ formatDate(item.transmitYmd, 'yyyy-MM-dd') }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="lastest-information h-1/2">
          <div class="flex justify-between mb-1">
            <div class="text-[#202857] pt-[8px] text-lg font-black">Consignment List</div>
            <div class="more-btn">
              More
              <Icon icon="foundation:plus" width="20" height="20" class="text-[#202857] ml-2" />
            </div>
          </div>
          <div class="list-content pr-2">
            <div v-for="item of mockupData.consignment" class="flex">
              <div class="text-[#202857] mr-[12px] mt-[1px]">•</div>
              <div class="flex justify-between item">
                <span>{{ item.title }}</span> <span>{{ item.date }}</span>
              </div>
            </div>
          </div>
          </div> -->
        <div class="bg mt-4 flex help">
          <div class="flex gap-[10px] flex-col w-full">
            <div class="border-b flex py-4 flex-col">
              <div class="flex cursor-pointer support" @click="handleSupportPage('F1')">
                <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                  <img
                    src="@/assets/images/support-down.png"
                    :alt="$t('ewms.labels.downloadManual')"
                    class="bg-img"
                  />
                </div>
                <div class="ml-4 pt-1">
                  <div class="text-xs text-[#202857] pb-1">Support</div>
                  <div class="text-lg text-[#202857] font-extrabold">
                    {{ $t('common.labels.downloadManual') }}
                  </div>
                </div>
              </div>
              <div class="flex cursor-pointer support" @click="handleSupportPage('F2')">
                <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                  <img src="@/assets/images/support-icon-1.png" alt="Support FAQ" class="bg-img" />
                </div>
                <div class="ml-4 pt-1">
                  <div class="text-xs text-[#202857] pb-1">Support</div>
                  <div class="text-lg text-[#202857] font-extrabold">FAQ</div>
                </div>
              </div>
              <div class="flex cursor-pointer support" @click="handleSupportPage('F3')">
                <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                  <img src="@/assets/images/support-qna.png" alt="Support Q & A" class="bg-img" />
                </div>
                <div class="ml-4 pt-1">
                  <div class="text-xs text-[#202857] pb-1">Support</div>
                  <div class="text-lg text-[#202857] font-extrabold">Q & A</div>
                </div>
              </div>
              <div class="flex cursor-pointer support" @click="handleSupportPage('F4')">
                <div class="w-[60px] h-[60px] bg-[#dadef4] flex justify-center align-center">
                  <img
                    src="@/assets/images/support-icon-3.png"
                    :alt="$t('ewms.labels.cyberReportingCenter')"
                    class="bg-img"
                  />
                </div>
                <div class="ml-4 pt-1">
                  <div class="text-xs text-[#202857] pb-1">Support</div>
                  <div class="text-lg text-[#202857] font-extrabold">
                    {{ $t('common.labels.cyberReportingCenter') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <NoticePopup ref="noticePopupRef" />
  <BoardDetailPopup
    :data="boardRowSelected"
    :visible="isVisibleBoardDetail"
    @close="handleCloseBoardDetail"
    @refresh="handleCloseBoardDetail"
  />
</template>

<script setup lang="ts">
import { defineAsyncComponent, onMounted, ref } from 'vue'
import { formatDate } from 'shared/utils'

import { CommonService } from '@/services/commons'
import { MODULE_CODES } from '@/shared/constants'
import { useMainStore } from '@/stores/main'
import { Board, LastestInfo, LastestItem, Notice, ProgressCount } from '@/types'
import NoticePopup from '@/views/components/NoticePopup.vue'

import mockupData from './mockup.json'

/**************************************************************************
 * Variable Definition
 **************************************************************************/
// defineAsyncComponent로 불러온 컴포넌트 직접 선언
const BoardDetailPopup = defineAsyncComponent(() => import('board/BoardDetailPopup.vue'))

const boardRowSelected = ref<Board>()
const isVisibleBoardDetail = ref<boolean>(false)

const commonService = new CommonService()
const noticePopupRef = ref(null)
const isLoading = ref<boolean>(false)
const isLoadingLastestInfo = ref<boolean>(false)
const activeTab = ref<string>('')

const notices = ref<Notice[]>([])

const lastestInfo = ref<LastestInfo>({
  supplyReceiptList: [],
  vesselMovementReceiptList: [],
})

const userSupportTab = [
  {
    id: 'supplyReceiptList',
    label: 'Supply',
    url: '/ewms/goods-receipt/supply-receipt',
  },
  {
    id: 'vesselMovementReceiptList',
    label: 'Vessel Movement',
    url: '/ewms/goods-receipt/vessel-movement-receipt',
  },
]

const userSupportOtherTab = [
  {
    id: 'supplyReceiptList',
    label: 'Supply',
    url: '/ewms/goods-receipt/supply-receipt',
  },
  {
    id: 'vesselMovementReceiptList',
    label: 'Vessel Movement',
    url: '/ewms/goods-receipt/vessel-movement-receipt',
  },
]

const userSupport = ref([
  {
    id: 'supplyReceipt',
    label: 'Supply Receipt',
    count: '',
    url: '/ewms/goods-receipt/supply-receipt',
  },
  {
    id: 'movementReceipt',
    label: 'Movement Receipt',
    subLabel: 'Vessel',
    count: '',
    url: '/ewms/goods-receipt/vessel-movement-receipt',
  },
  {
    id: 'supplyRelease',
    label: 'Supply Release',
    count: '',
    url: '/ewms/goods-release/supply-release',
  },
  {
    id: 'movementRelease',
    label: 'Movement Release',
    subLabel: 'Vessel',
    count: '',
    url: '/ewms/goods-release/vessel-movement-release',
  },
])

const activeOtherTab = ref<string>(userSupportOtherTab[0].id)

/**************************************************************************
 * Lifecycle Hooks
 **************************************************************************/
onMounted(() => {
  getBoardList()
  getLastestInfo()
  getProgressCount()
})

/**************************************************************************
 * Computed Properties
 **************************************************************************/

/**************************************************************************
 * Watchers
 **************************************************************************/

/**************************************************************************
 * Event Handlers
 **************************************************************************/

const changeTab = (tabId: string) => {
  activeTab.value = tabId
}

const changeOtherTab = (tabId: string) => {
  activeOtherTab.value = tabId
}

const handleShowPopup = () => {
  noticePopupRef.value.showNoticePopup()
}

const handleItemClick = (item: LastestItem) => {
  let url = ''
  if (activeTab.value === userSupportTab[0].id) {
    url =
      userSupportTab[0].url +
      '?vmsCompanyCode=' +
      item.vmsCompanyCode +
      '&vesselCode=' +
      item.vesselCode +
      '&purchaseOrderNumber=' +
      item.purchaseOrderNumber +
      '&baseGoodsReceiptYmd=' +
      item.baseGoodsReceiptYmd +
      '&goodsReceiptPlanYmd=' +
      item.goodsReceiptPlanYmd +
      ''
  } else if (activeTab.value === userSupportTab[1].id) {
    url =
      userSupportTab[1].url +
      '?vmsCompanyCode=' +
      item.vmsCompanyCode +
      '&vesselCode=' +
      item.vesselCode +
      '&orderNumber=' +
      item.orderNumber +
      '&warehouseCode=' +
      item.warehouseCode +
      '&vmsBoundCode=' +
      item.vmsBoundCode +
      ''
  }

  if (url !== '') {
    window.location.href = url
  }
}

const handleOtherItemClick = (item: LastestItem) => {
  let url = ''
  if (activeOtherTab.value === userSupportOtherTab[0].id) {
    url =
      userSupportOtherTab[0].url +
      '?vmsCompanyCode=' +
      item.vmsCompanyCode +
      '&vesselCode=' +
      item.vesselCode +
      '&purchaseOrderNumber=' +
      item.purchaseOrderNumber +
      '&baseGoodsReceiptYmd=' +
      item.baseGoodsReceiptYmd +
      '&goodsReceiptPlanYmd=' +
      item.goodsReceiptPlanYmd +
      ''
  } else if (activeOtherTab.value === userSupportOtherTab[1].id) {
    url =
      userSupportOtherTab[1].url +
      '?vmsCompanyCode=' +
      item.vmsCompanyCode +
      '&vesselCode=' +
      item.vesselCode +
      '&orderNumber=' +
      item.orderNumber +
      '&warehouseCode=' +
      item.warehouseCode +
      '&vmsBoundCode=' +
      item.vmsBoundCode +
      ''
  }

  if (url !== '') {
    window.location.href = url
  }
}
const handleReload = () => {
  //초기화
  userSupport.value.forEach((item) => {
    item.count = ''
  })
  lastestInfo.value.supplyReceiptList = []
  lastestInfo.value.vesselMovementReceiptList = []

  getLastestInfo()
  getProgressCount()
}
const handleMorePage = () => {
  let url = ''
  if (activeTab.value === userSupportTab[0].id) {
    url = userSupportTab[0].url
  } else if (activeTab.value === userSupportTab[1].id) {
    url = userSupportTab[1].url
  }

  if (url !== '') {
    window.location.href = url
  }
}

const handleOtherMorePage = () => {
  let url = ''
  if (activeOtherTab.value === userSupportOtherTab[0].id) {
    url = userSupportOtherTab[0].url
  } else if (activeOtherTab.value === userSupportOtherTab[1].id) {
    url = userSupportOtherTab[1].url
  }

  if (url !== '') {
    window.location.href = url
  }
}

const handleGoPage = (pageType: string) => {
  let url = ''
  if (pageType === userSupport.value[0].id) {
    url = userSupport.value[0].url
  } else if (pageType === userSupport.value[1].id) {
    url = userSupport.value[1].url
  } else if (pageType === userSupport.value[2].id) {
    url = userSupport.value[2].url
  } else if (pageType === userSupport.value[3].id) {
    url = userSupport.value[3].url
  }

  if (url !== '') {
    window.location.href = url
  }
}

const handleSupportPage = async (pageType: string) => {
  let url = ''
  if (pageType === 'F1') {
    const mainStore = useMainStore()
    mainStore.showLoading()
    try {
      const { data } = await commonService.getAdminConfiguration(MODULE_CODES.EWMS)
      if (data?.contactVmsFileId) {
        const fileDetails = await commonService.getFiles(Number(data.systemManualVmsFileId))
        const file = fileDetails.data[0]
        await commonService.file(file, false, false, true)
      }
    } catch (error) {
      console.error('Error loading PDF:', error)
    } finally {
      mainStore.hideLoading()
    }
    return
  } else if (pageType === 'F2') {
    url = '/support/board/faq'
  } else if (pageType === 'F3') {
    url = '/support/board/qa'
  } else if (pageType === 'F4') {
    //url = 'https://www.hmm21.com/ethics/informantReg.do?lang=en'
    const userLanguage = ref<string>(navigator.language || 'en')
    url = 'https://www.hmm21.com/ethics/informantReg.do?lang=' + userLanguage.value
    window.open(url, '_blank')
    return
  }

  if (url !== '') {
    window.location.href = url
  }
}

const handleNoticePage = () => {
  window.location.href = '/support/board/notice'
}

const handleShowBoardDetail = () => {
  isVisibleBoardDetail.value = true
}

const handleCloseBoardDetail = () => {
  isVisibleBoardDetail.value = false
}

const handleBoardRowDbClick = (item) => {
  boardRowSelected.value = item
  handleShowBoardDetail()
}

/**************************************************************************
 * Functions
 **************************************************************************/
const getBoardList = () => {
  isLoading.value = true
  const params = {
    boardType: 'NOTICE',
    moduleCode: MODULE_CODES.EWMS,
    page: 0,
    size: 5,
  }
  commonService
    .getNotices(params)
    .then((response) => {
      notices.value = response.data
    })
    .finally(() => {
      isLoading.value = false
    })
}

const getLastestInfo = () => {
  isLoadingLastestInfo.value = true
  const params = {
    page: 0,
    size: 14,
  }

  commonService
    .getLastestInfo(params)
    .then((response) => {
      lastestInfo.value.supplyReceiptList = response.data.supplyReceiptList
      lastestInfo.value.vesselMovementReceiptList = response.data.vesselMovementReceiptList
    })
    .finally(() => {
      isLoadingLastestInfo.value = false
    })
}

const getProgressCount = () => {
  //isLoading.value = true
  const params = {
    page: 0,
    size: 5,
  }

  commonService
    .getProgressCount(params)
    .then((response) => {
      userSupport.value[0].count = response.data.supplyReceiptCount.cnt
      userSupport.value[1].count = response.data.vesselMovementReceiptCount.cnt
      userSupport.value[2].count = response.data.supplyReleaseCount.cnt
      userSupport.value[3].count = response.data.vesselMovementReleaseCount.cnt
    })
    .finally(() => {
      //changeTab('supplyReceiptList')
      //changeOtherTab('vesselMovementReceiptList')
      changeTab(userSupportTab[0].id)
      changeOtherTab(userSupportOtherTab[1].id)
      //isLoading.value = false
    })
}
</script>
<style lang="scss">
.main-page-container {
  width: 100%;
  height: 100%;
  display: flex;

  .bg-content {
    background-size: cover;
    background: url('@/assets/images/bg-wms.jpg') no-repeat right bottom;
    border-radius: 5px;
    background-size: cover;

    .labels {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      opacity: 0.5;
      gap: 10px;
    }
  }

  .more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #202857;
  }

  .notice {
    display: flex;
    flex-direction: column;
    height: 275px;
    overflow: hidden;
  }

  .lastest-information {
    display: flex;
    flex-direction: column;
  }

  .list-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .item {
      flex-grow: 1;
      color: #000;
      font-weight: 700;
      line-height: 2rem;
    }
  }

  .cate {
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 15px;
    height: 130px;
    width: 310px;
    width: 48%;

    .cate-label {
      font-weight: 700;
      text-align: right;
    }

    .cate-count {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      font-size: 40px;
    }

    .cate-globe {
      width: 30px;
      height: 30px;
      border-radius: 0;
    }
  }

  .supplyReceipt {
    border-color: #7f9eda;
    color: #1d3c7a;
    background: url(@/assets/images/icon-progress-quotation.svg);
    background-repeat: no-repeat;
    background-position: 10px 60px;
    background-size: 17%;
    background-color: #b2c8f3;
  }

  .movementReceipt {
    border-color: #80c6b3;
    color: #026a4d;
    background: url(@/assets/images/icon-progress-order.svg);
    background-repeat: no-repeat;
    background-position: 10px 60px;
    background-size: 16%;
    background-color: #afe6d7;
  }

  .supplyRelease {
    border-color: #ccb57e;
    color: #906b12;
    background: url(@/assets/images/icon-progress-claim.svg);
    background-repeat: no-repeat;
    background-position: 10px 60px;
    background-size: 17%;
    background-color: #e6d6af;
  }
  .movementRelease {
    border-color: #abba6d;
    color: #718b0c;
    background: url(@/assets/images/icon-progress-invoice.svg);
    background-repeat: no-repeat;
    background-position: 10px 60px;
    background-size: 16%;
    background-color: #d4e19e;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cbcbcb;
    opacity: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cate-globe svg {
    display: none;
  }

  .el-button:hover {
    background-color: transparent !important;
  }

  .tab-container {
    border-bottom: solid 1px #cbcbcb;
  }

  .tab-item-control {
    border: none;
    padding: 0;
    position: relative;

    span {
      width: 100% !important;
    }

    .el-button + .el-button {
      margin-left: 0px;
    }

    .custom-tab-control {
      width: 100%;
      border: none;
      border-width: 1px;
      border-color: #cbcbcb;
      border-radius: 5px 5px 0 0;
      color: #5e5e5e;
      padding: 8px 20px 7px 20px;
      letter-spacing: -0.5px;
      background: #ffffff;
    }

    .custom-tab-control.action {
      border-style: solid;
      border-color: #ee312f;
      color: #000000;
      font-weight: bold;
      background-color: #fff;
      box-shadow: inset 0 2px 0 #ee312f;
      border-bottom: 1px solid #fff;
      margin-bottom: -2px;
    }

    .custom-tab-control.action::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #ec3834;
      top: 2px;
      left: 50%;
      margin-left: -4px;
    }

    button:has(.custom-tab-control.action) + button:has(.custom-tab-control) {
      margin-left: 0px;
    }
  }
}

.footer {
  color: #555;

  .menu-item + .menu-item:before {
    content: '/';
    position: absolute;
    margin-left: -20px;
    font-weight: 200;
  }
}

.round-box {
  padding: 0px;
  border-radius: 5px;
  border: 1px solid #c7cede;

  .title {
    background: #eff4ff;
    padding: 10px;
    padding: 5px 10px;
    font-size: 0.95em;
    color: #202857;
    font-weight: 800;
  }

  .date {
    color: #9f9f9f;
    font-weight: 600;
  }

  .list {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
  .h-55vh {
    height: 55vh;
  }
}

.new {
  overflow: hidden;
  height: 450px;
}

.border-b {
  border-bottom: 1px solid #cbcbcb;
}

.border-b:has(.tab-item-control) {
  height: 34px;
}

.bg {
  background: #ffffff;
  border: 1px solid #c7cede;
  border-radius: 5px;
  padding: 0 15px;
}

.info-header {
  background: #dde0f3;
  padding: 7px 5px;

  span {
    width: 20%;
    text-align: center;
  }
}
.slogan {
  color: #fff;
  padding: 30px 20px;
  line-height: 2rem;
  font-size: 1rem;
  strong {
    font-size: 2rem;
  }
  p {
    text-shadow: 0px 0px 10px #000000;
  }
}
.support + .support {
  margin-top: 10px;
}
.help {
  height: 450px;
}
</style>
