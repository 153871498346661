<template>
  <el-descriptions :column="2" border class="overflow-auto">
    <el-descriptions-item label-align="right" span="2">
      <template #label>ID</template>
      <el-input v-model="form.companyCode" class="!w-[240px]" />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Business</template>
      <el-select v-model="form.tradeTypeCode">
        <el-option
          v-for="item in businessOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Business Type</template>
      <el-select v-model="form.tradeTypeCode">
        <el-option
          v-for="item in businessTypeOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Trade Name</template>
      <el-input v-model="form.informationTradeName" />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Zipcode</template>
      <el-input v-model="form.zipCode" />
    </el-descriptions-item>
    <el-descriptions-item label-align="right" span="2">
      <template #label>Address</template>
      <el-input v-model="form.firstAddress" />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Contact Number</template>
      <el-input v-model="form.residencePhoneNumber" />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>E-mail</template>
      <el-input v-model="form.residenceEmailAddress" />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Fax</template>
      <el-input v-model="form.residenceFaxNumber" />
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Nation</template>
      <el-select v-model="form.nationCode">
        <el-option
          v-for="item in nationOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>State</template>
      <el-select v-model="form.regionCode">
        <el-option
          v-for="item in regionOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </el-descriptions-item>
    <el-descriptions-item label-align="right">
      <template #label>Location</template>
      <el-select v-model="form.locationCode">
        <el-option
          v-for="item in locationOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </el-descriptions-item>
  </el-descriptions>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, watchEffect } from 'vue'
import { CACHE, getCache } from 'shared'

import { CommonService } from '@/services/commons'
import { NationCodeParams, Profile } from '@/types'

// Import vue -> external -> component -> dev -> local (alphabet) -> @types

defineOptions({
  name: '',
})

const commonService = new CommonService()

// Emits
const emit = defineEmits(['getCompanyProfile'])

// Props
const props = defineProps({
  companyProfile: {
    type: Object,
    required: true,
    default: () => ({}),
  },
})

// Constants

// Stores

// Composables

// Refs
const form = ref<Profile>({
  companyCode: '',
  tradeTypeCode: '',
  informationTradeName: '',
  firstAddress: '',
  residencePhoneNumber: '',
  residenceFaxNumber: '',
  regionCode: '',
  tradeCompanyCode: '',
  zipCode: '',
  residenceEmailAddress: '',
  nationCode: '',
  locationCode: '',
  vmsCompanyCode: '',
  vesselCode: '',
  nationName: '',
})

const businessOptions = ref([])
const businessTypeOptions = ref([])
const nationOptions = ref([])
const regionOptions = ref([])
const locationOptions = ref([])

// Computed

// Reactive

// Watch
watch(
  form,
  (newValue) => {
    emit('getCompanyProfile', newValue)
  },
  { deep: true }
)

watch(
  () => form.value.nationCode,
  async (newNationCode) => {
    if (newNationCode) {
      const params = {
        ncd: newNationCode,
        q: '',
      }
      await getPortList(params)
    }
  }
)

watchEffect(() => {
  if (props.companyProfile) {
    form.value = {
      companyCode: props.companyProfile.companyCode || '',
      tradeTypeCode: props.companyProfile.tradeTypeCode || '',
      informationTradeName: props.companyProfile.informationTradeName || '',
      firstAddress: props.companyProfile.firstAddress || '',
      residencePhoneNumber: props.companyProfile.residencePhoneNumber || '',
      residenceFaxNumber: props.companyProfile.residenceFaxNumber || '',
      regionCode: props.companyProfile.regionCode || '',
      tradeCompanyCode: props.companyProfile.tradeCompanyCode || '',
      zipCode: props.companyProfile.zipCode || '',
      residenceEmailAddress: props.companyProfile.residenceEmailAddress || '',
      nationCode: props.companyProfile.nationCode || '',
      locationCode: props.companyProfile.locationCode || '',
      vmsCompanyCode: props.companyProfile.vmsCompanyCode || '',
      vesselCode: props.companyProfile.vesselCode || '',
      nationName: props.companyProfile.nationName || '',
    }
  }
})

// Hooks
onMounted(async () => {
  await getNationList()
  const commonCodes = getCache(CACHE.COMMON_CODES)
  if (commonCodes) {
    businessOptions.value = commonCodes.TRDE_TP_CD.map((item) => ({
      id: item.vmsCommonCode,
      label: item.vmsCommonName,
    }))
    businessTypeOptions.value = commonCodes.TRDE_CMPNY_CD.map((item) => ({
      id: item.vmsCommonCode,
      label: item.vmsCommonName,
    }))
    regionOptions.value = commonCodes.RGN_CD.map((item) => ({
      id: item.vmsCommonCode,
      label: item.vmsCommonName,
    }))
  }
})

// Functions
const getNationList = async () => {
  const { data } = await commonService.getNationList()
  nationOptions.value = data.map((item) => ({
    id: item.nationCode,
    label: item.nationName,
  }))
}

const getPortList = async (params: NationCodeParams) => {
  const { data } = await commonService.getPortList(params)
  locationOptions.value = data.map((item) => ({
    id: item.portCode,
    label: item.portName,
  }))
}
// Expose
</script>
